<template>
  <aside class="flex flex-col w-full space-y-6 bg-white" aria-label="Sidebar">
    <div>
      <div class="flex justify-between">
        <router-link to="/dashboard" class="px-5 py-3 flex justify-between md:items-center">
          <!-- <img class="rounded-t-lg w-40" v-if="logo" :src="`${$baseUrl}/InformasiDasar/${logo}`" alt="" /> -->
          <span class="text-xl font-bold text-ekinblue">E-Kinerja</span>
        </router-link>
        <div class="flex flex-col">
          <span class="my-auto md:hidden px-6 text-ekinblue" @click="$emit('toggleSide')">
            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
          </span>
        </div>
      </div>
      <div class="text-xs text-ekinblue px-5">{{ new Intl.DateTimeFormat("id", { dateStyle: "full", timeStyle: "short" }).format(now) }}</div>
    </div>
    <div class="overflow-y-auto pr-3 rounded h-full min-h-screen">
      <ul class="space-y-3 text-base font-normal">
        <li :class="$route.name === 'dashboard' ? 'pl-3 text-white font-bold bg-ekinblue border-ekinblue rounded-r-full border-r-8 ease-in-out duration-300' : 'text-ekinblue hover:text-ekinblue ease-in-out duration-300'">
          <router-link :to="{ name: 'dashboard' }" @click="$emit('toggleSide')" class="flex items-center p-2 px-3 rounded-lg">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
            </svg>
            <span class="ml-3 text-sm">Dashboard</span>
          </router-link>
        </li>
        <li></li>
      </ul>
      <ul v-if="role == 'administrator'" class="pt-4 mt-4 space-y-2 border-t border-gray-200">
        <li class="text-ekinblue font-semibold">
          <a href="#" class="flex items-center p-2 px-3 rounded-lg">
            <span>Master Data</span>
          </a>
        </li>
        <li :class="route === 'opd' ? 'pl-3 text-white font-bold bg-ekinblue border-ekinblue rounded-r-full border-r-8 ease-in-out duration-300' : 'text-ekinblue hover:text-ekinblue ease-in-out duration-300'">
          <router-link :to="{ name: 'opd' }" @click="$emit('toggleSide')" class="flex items-center p-2 px-3 rounded-lg">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z"></path>
            </svg>
            <span class="ml-3 text-sm">OPD</span>
          </router-link>
        </li>
        <li :class="route === 'hari-libur' ? 'pl-3 text-white font-bold bg-ekinblue border-ekinblue rounded-r-full border-r-8 ease-in-out duration-300' : 'text-ekinblue hover:text-ekinblue ease-in-out duration-300'">
          <router-link :to="{ name: 'hari-libur' }" @click="$emit('toggleSide')" class="flex items-center p-2 px-3 rounded-lg">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z"></path>
            </svg>
            <span class="ml-3 text-sm">Hari Libur</span>
          </router-link>
        </li>
        <li :class="route === 'jabatan' ? 'pl-3 text-white font-bold bg-ekinblue border-ekinblue rounded-r-full border-r-8 ease-in-out duration-300' : 'text-ekinblue hover:text-ekinblue ease-in-out duration-300'">
          <router-link :to="{ name: 'jabatan' }" @click="$emit('toggleSide')" class="flex items-center p-2 px-3 rounded-lg">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clip-rule="evenodd"></path>
              <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
            </svg>
            <span class="ml-3 text-sm">Jabatan</span>
          </router-link>
        </li>
        <li :class="route === 'pegawai' ? 'pl-3 text-white font-bold bg-ekinblue border-ekinblue rounded-r-full border-r-8 ease-in-out duration-300' : 'text-ekinblue hover:text-ekinblue ease-in-out duration-300'">
          <router-link :to="{ name: 'pegawai' }" @click="$emit('toggleSide')" class="flex items-center p-2 px-3 rounded-lg">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
              <path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path>
            </svg>
            <span class="ml-3 text-sm">Pegawai</span>
          </router-link>
        </li>
        <li :class="route === 'verifikator' ? 'pl-3 text-white font-bold bg-ekinblue border-ekinblue rounded-r-full border-r-8 ease-in-out duration-300' : 'text-ekinblue hover:text-ekinblue ease-in-out duration-300'">
          <router-link :to="{ name: 'verifikator' }" @click="$emit('toggleSide')" class="flex items-center p-2 px-3 rounded-lg">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
              <path fill-rule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clip-rule="evenodd"></path>
            </svg>
            <span class="ml-3 text-sm">Verifikator</span>
          </router-link>
        </li>
        <li :class="route === 'user' ? 'pl-3 text-white font-bold bg-ekinblue border-ekinblue rounded-r-full border-r-8 ease-in-out duration-300' : 'text-ekinblue hover:text-ekinblue ease-in-out duration-300'">
          <router-link :to="{ name: 'user' }" @click="$emit('toggleSide')" class="flex items-center p-2 px-3 rounded-lg">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path>
            </svg>
            <span class="ml-3 text-sm">User</span>
          </router-link>
        </li>
        <li :class="route === 'dokumen-persyaratan' ? 'pl-3 text-white font-bold bg-ekinblue border-ekinblue rounded-r-full border-r-8 ease-in-out duration-300' : 'text-ekinblue hover:text-ekinblue ease-in-out duration-300'">
          <router-link :to="{ name: 'dokumen-persyaratan' }" @click="$emit('toggleSide')" class="flex items-center p-2 px-3 rounded-lg">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z"></path>
              <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z"></path>
            </svg>
            <span class="ml-3 text-sm">Persyaratan Dokumen</span>
          </router-link>
        </li>
        <li :class="route === 'penanda-tangan' ? 'pl-3 text-white font-bold bg-ekinblue border-ekinblue rounded-r-full border-r-8 ease-in-out duration-300' : 'text-ekinblue hover:text-ekinblue ease-in-out duration-300'">
          <router-link :to="{ name: 'penanda-tangan' }" @click="$emit('toggleSide')" class="flex items-center p-2 px-3 rounded-lg">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path>
              <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path>
            </svg>
            <span class="ml-3 text-sm">Penanda Tangan</span>
          </router-link>
        </li>
      </ul>
      <ul v-if="role == 'administrator'" class="pt-4 mt-4 space-y-2 border-t border-gray-200">
        <li class="text-ekinblue font-semibold">
          <a href="#" class="flex items-center p-2 px-3 rounded-lg">
            <span>Landingpage</span>
          </a>
        </li>
        <li :class="route === 'master-informasi' ? 'pl-3 text-white font-bold bg-ekinblue border-ekinblue rounded-r-full border-r-8 ease-in-out duration-300' : 'text-ekinblue hover:text-ekinblue ease-in-out duration-300'">
          <router-link :to="{ name: 'master-informasi' }" @click="$emit('toggleSide')" class="flex items-center p-2 px-3 rounded-lg">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z"></path>
            </svg>
            <span class="ml-3 text-sm">Informasi</span>
          </router-link>
        </li>
        <!-- <li :class="route === 'master-galeri' ? 'pl-3 text-white font-bold bg-ekinblue border-ekinblue rounded-r-full border-r-8 ease-in-out duration-300' : 'text-ekinblue hover:text-ekinblue ease-in-out duration-300'">
          <router-link :to="{ name: 'master-galeri' }" @click="$emit('toggleSide')" class="flex items-center p-2 px-3 rounded-lg">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z"></path>
            </svg>
            <span class="ml-3 text-sm">Galeri</span>
          </router-link>
        </li> -->
        <li :class="route === 'master-berita' ? 'pl-3 text-white font-bold bg-ekinblue border-ekinblue rounded-r-full border-r-8 ease-in-out duration-300' : 'text-ekinblue hover:text-ekinblue ease-in-out duration-300'">
          <router-link :to="{ name: 'master-berita' }" @click="$emit('toggleSide')" class="flex items-center p-2 px-3 rounded-lg">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z"></path>
            </svg>
            <span class="ml-3 text-sm">Berita</span>
          </router-link>
        </li>
        <li :class="route === 'master-produk-hukum' ? 'pl-3 text-white font-bold bg-ekinblue border-ekinblue rounded-r-full border-r-8 ease-in-out duration-300' : 'text-ekinblue hover:text-ekinblue ease-in-out duration-300'">
          <router-link :to="{ name: 'master-produk-hukum' }" @click="$emit('toggleSide')" class="flex items-center p-2 px-3 rounded-lg">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z"></path>
            </svg>
            <span class="ml-3 text-sm">Produk Hukum</span>
          </router-link>
        </li>
      </ul>
      <ul v-if="role == 'administrator'" class="pt-4 mt-4 space-y-2 border-t border-gray-200">
        <li class="text-ekinblue font-semibold">
          <a href="#" class="flex items-center p-2 px-3 rounded-lg">
            <span>Pengaturan</span>
          </a>
        </li>
        <li :class="route === 'menu' ? 'pl-3 text-white font-bold bg-ekinblue border-ekinblue rounded-r-full border-r-8 ease-in-out duration-300' : 'text-ekinblue hover:text-ekinblue ease-in-out duration-300'">
          <router-link :to="{ name: 'menu' }" @click="$emit('toggleSide')" class="flex items-center p-2 px-3 rounded-lg">
            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
            <span class="ml-3 text-sm">Menu</span>
          </router-link>
        </li>
        <li :class="route === 'hak-akses' ? 'pl-3 text-white font-bold bg-ekinblue border-ekinblue rounded-r-full border-r-8 ease-in-out duration-300' : 'text-ekinblue hover:text-ekinblue ease-in-out duration-300'">
          <router-link :to="{ name: 'hak-akses' }" @click="$emit('toggleSide')" class="flex items-center p-2 px-3 rounded-lg">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="ml-3 text-sm">Hak Akses</span>
          </router-link>
        </li>
      </ul>
      <ul v-else class="pt-4 mt-4 space-y-2 border-t border-gray-200">
        <li v-for="(item, id) in menuRole" :key="id" :class="route === item.url ? 'pl-3 text-white font-bold bg-ekinblue border-ekinblue rounded-r-full border-r-8 ease-in-out duration-300' : 'text-ekinblue hover:text-ekinblue ease-in-out duration-300'">
          <router-link :to="{ name: item.url }" @click="$emit('toggleSide')" class="flex items-center p-2 px-3 rounded-lg">
            <div v-html="item.icon"></div>
            <span class="ml-3 text-sm">{{ item.nama_menu }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, onBeforeMount, computed } from "vue";
export default {
  setup() {
    const logo = ref("");
    const role = ref(window.localStorage.getItem("role"));
    const menuRole = ref([]);
    const now = ref(new Date());
    const urutan = ref([
      "jabatan",
      "shift",
      "pegawai",
      "tpp",
      "analisis-jabatan",
      "laporan-kinerja",
      "penilaian-pegawai",
      "laporan-opd",
      "akses-laporan",
      "manage-laporan",
      "input-absensi",
      "rekapitulasi-pengajuan-tpp",
      "penerbitan-tpp",
      "penanda-tangan",
      "rekapan-absensi-opd",
      "rekapan-absensi-opd-perhari",
    ]);
    const route = computed(() => {
      return useRouter().currentRoute.value.meta.menu;
    });

    setInterval(() => {
      now.value = new Date();
    }, 1000);

    onBeforeMount(() => {
      getMenu();
      getInformasi();
    });

    const getInformasi = async () => {
      const response = await fetch(`${process.env.VUE_APP_ENDPOINT}/informasi-dasar/api/V1/getInformasiDasar/public`, { credentials: "include" });
      const menu = await response.json();
      if (menu.hasOwnProperty("data")) {
        logo.value = menu.data.logo;
      }
    };
    const getMenu = async () => {
      let role = window.localStorage.getItem("role");
      const response = await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/menu/akses/sidebar/${role}`, { credentials: "include" });
      const menu = await response.json();
      const filteredMenu = menu.data.filter((item) => item.view);
      const sortedMenu = urutan.value.map((item) => {
        let temp = {};
        filteredMenu.forEach((filtered) => {
          if (filtered.url == item) {
            temp = filtered;
          }
        });
        return temp;
      });
      menuRole.value = sortedMenu.filter((item) => item && Object.keys(item).length > 0);
    };
    return { route, logo, role, menuRole, now };
  },
};
</script>
