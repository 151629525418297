<template>
  <section id="kontenberita" class="bg-[url('~assets/images/landingpage/sec2.svg')] bg-top bg-no-repeat bg-contain">
    <div class="container mx-auto px-6 md:px-10 pt-20 md:pt-64">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-10 mb-24">
        <div class="col-span-2">
          <div v-animate-onscroll="'animate__animated animate__fadeInLeft'" v-if="berita.length > 0">
            <div class="flex flex-col space-y-5">
              <span class="text-xl md:text-4xl font-semibold text-ekinblue">{{ berita[0].judul }}</span>
              <div class="flex justify-between">
                <span class="text-ekingray text-xs md:text-xs">{{ new Intl.DateTimeFormat("id", { dateStyle: "full" }).format(new Date(berita[0].tgl_berita)) }}</span>
                <span class="bg-blue-100 text-ekinblue text-xs md:text-xs font-semibold mr-2 px-3 py-0.5 rounded-full">{{ berita[0].kategori }}</span>
              </div>
              <img :src="`${$baseUrl}/berita/${berita[0].foto}`" alt="" class="w-full" />
              <div class="text-base md:text-sm text-justify" v-html="berita[0].isi"></div>
            </div>
          </div>
        </div>
        <div class="col-span-1 border rounded-md py-4">
          <div v-animate-onscroll="'animate__animated animate__fadeInRight'">
            <div class="flex-flex-col space-y-5">
              <span class="text-lg md:text-4xl px-4 font-semibold text-ekinblue">Berita Terkait</span>
              <div class="flex flex-col space-y-3 max-h-96 overflow-auto">
                <div @click="()=>id = item.id" class="flex space-x-3 px-4 py-2 hover:cursor-pointer items-center hover:bg-gray-100" v-if="terkait.length > 0" v-for="item in terkait">
                  <img :src="`${$baseUrl}/berita/${item.foto}`" class="w-1/6" />
                  <div>
                    <p class="text-base font-medium">{{ item.judul }}</p>
                    <p>
                      <span class="text-ekingray text-xs md:text-xs">{{ new Intl.DateTimeFormat("id", { dateStyle: "full" }).format(new Date(berita[0].tgl_berita)) }}</span>
                    </p>
                    <p>
                      <span class="bg-blue-100 text-ekinblue text-xs md:text-xs font-semibold mr-2 px-3 py-0.5 rounded-full">{{ berita[0].kategori }}</span>
                    </p>
                  </div>
                  <hr />
                </div>
                <div v-else class="flex flex-col py-10 px-2 items-center">
                  <img src="@/assets/images/illustrations/oc-browse.svg" alt="" class="md:w-2/12 w-3/12" />
                  <div class="flex flex-col md:w-9/12 w-9/12 text-ekinblue items-center">
                    <span class="text-lg font-bold">Data Kosong!</span>
                    <span class="">Berita tidak ditemukan!</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { onBeforeMount, onMounted, ref, watch } from "vue";
import { useRoute } from 'vue-router';
export default {
  setup(props) {
    const berita = ref([]);
    const terkait = ref([]);
    const showBerita = ref(false);
    const kategori = ref("");
    const route = useRoute()
    const id = ref(route.params.id)
    onBeforeMount(() => {
      getBerita();
    });


    watch(id,(x)=>getBerita())
    

    const getBerita = () => {
      Promise.all([fetch(`${process.env.VUE_APP_ENDPOINT}/berita/api/V1/getOneBeritaPublic/${id.value}`)])
        .then(async ([data]) => {
          const result = await data.json();
          berita.value = [result.data];
          kategori.value = result.data.kategori;
          console.log(result.data);
          getBeritaTerkait();
          showBerita.value = true;
        })
        .catch((err) => console.log(err));
    };
    const getBeritaTerkait = () => {
      Promise.all([fetch(`${process.env.VUE_APP_ENDPOINT}/berita/api/V1/dataBeritaByKategori/public/${kategori.value}`)])
        .then(async ([data]) => {
          const result = await data.json();
          terkait.value = result.data;
        })
        .catch((err) => console.log(err));
    };
    return { id, berita, terkait, showBerita };
  },
};
</script>

<style scoped></style>
