<template>
  <div v-if="!load">
    <Navbar :informasi="informasi" :menu="menu" />
    <!-- <Navbarv2 :informasi="informasi" :menu="menu" /> -->
    <div class="block">
      <Section1 />
      <!-- <Section1v2 /> -->
      <Section2 />
      <Section3 />
      <Section4 />
    </div>
    <Section5 :informasi="informasi" :menu="menu" />
  </div>
  <div v-else v-animate-onscroll="'animate__animated animate__fadeInUp'">
    <div class="flex flex-col w-full min-h-screen bg-ekinblue">
      <div class="my-auto flex">
        <img src="@/assets/images/landingpage/loading.svg" class="mx-auto cursor-none" />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../../components/landingpage/navbar/Navbar.vue";
import Navbarv2 from "../../components/landingpage/navbar/Navbarv2.vue";
import Section1 from "../../components/landingpage/Section1.vue";
import Section1v2 from "../../components/landingpage/Section1v2.vue";
import Section2 from "../../components/landingpage/Section2.vue";
import Section3 from "../../components/landingpage/Section3.vue";
import Section4 from "../../components/landingpage/Section4.vue";
import Section5 from "../../components/landingpage/Section5.vue";
import { onBeforeMount, ref } from "vue";
export default {
  components: {
    Navbar,
    Navbarv2,
    Section1,
    Section1v2,
    Section2,
    Section3,
    Section4,
    Section5,
  },
  setup() {
    const load = ref(true);
    const informasi = ref({});
    const menu = ref([
      { route: "Beranda", href: "secondpage", active: false },
      { route: "Produk Hukum", href: "thirdpage", active: true },
      { route: "Berita", href: "fourthpage", active: true },
      { route: "Kontak Kami", href: "fifthpage", active: true },
    ]);

    onBeforeMount(() => {
      getInformasi();
      console.log(process.env.VUE_APP_ENDPOINT);
    });

    const getInformasi = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_ENDPOINT}/informasi-dasar/api/V1/getInformasiDasar/public`);
        const result = await response.json();
        if (result.hasOwnProperty("data")) {
          informasi.value = result.data;
        }
        load.value = false;
      } catch {
        load.value = false;
      }
    };
    return { menu, informasi, load };
  },
};
</script>

<style scoped>
.container {
  padding: 0 2rem;
}
</style>
