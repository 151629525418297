<template>
  <section id="firstpage" class="h-auto md:h-100 bg-ekinblue">
    <div class="container mx-auto py-24 md:py-20 space-y-10 px-5 md:px-10">
      <div class="text-white text-xl md:text-5xl font-semibold text-center flex flex-col mb-auto animate__animated animate__fadeInDown">
        <span>Penilaian Pegawai</span>
        <span>Dengan E-Kinerja</span>
      </div>
      <div class="mt-5 text-center text-ekinlightblue animate__animated animate__fadeIn animate__delay-1s">
        <span class="font-normal text-lg md:text-xl"
          >Melakukan Penilaian Secara Mudah <br class="md:hidden" />
          dan Akurat dengan Tampilan User Friendly</span
        >
      </div>
      <div class="text-center animate__animated animate__zoomIn animate__delay-1s">
        <router-link :to="{ name: 'login' }">
          <button class="bg-transparent hover:bg-lightblue-ekin text-white font-semibold hover:bg-blue-900 hover:border-0 hover:text-white px-14 py-4 border border-lightblue-ekin rounded-full">Get started</button>
        </router-link>
      </div>
      <div class="grid grid-cols-3 gap-1 mb-10">
        <div class="flex flex-col items-end pt-8 md:pt-0">
          <img src="@/assets/images/landingpage/mockup_1.png" class="w-5/12 md:w-5/12 animate__animated animate__fadeInTopLeft animate__delay-2s" />
          <img src="@/assets/images/landingpage/mockup_2.png" class="w-5/12 md:w-5/12 mr-5 sm:mr-5 md:mr-16 animate__animated animate__fadeInBottomLeft animate__delay-2s" />
        </div>
        <div class="pt-10 md:pt-16">
          <img src="@/assets/images/landingpage/mockup.png" class="w-100 mb-1 sm:mb-1 md:mb-24 transition ease-in-out delay-150 hover:-translate-y-1 animate__animated animate__zoomIn animate__delay-1s" />
        </div>
        <div class="flex flex-col pt-8 sm:pt-8 md:pt-0">
          <img src="@/assets/images/landingpage/mockup_3.png" class="w-5/12 md:w-5/12 animate__animated animate__fadeInTopRight animate__delay-2s" />
          <img src="@/assets/images/landingpage/mockup_4.png" class="w-5/12 md:w-5/12 ml-5 sm:ml-5 md:ml-16 animate__animated animate__fadeInBottomRight animate__delay-2s" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped></style>
