<template>
  <!-- <div v-if="mountChart"> -->
  <div class="grid grid-cols-12 min-h-screen">
    <div class="col-span-12 md:col-span-8 bg-ekinadminlightblue p-2 order-2 md:order-1">
      <div class="p-4 rounded-lg space-y-8">
        <div class="flex justify-between items-center">
          <div class="md:w-3/12 w-6/12">
            <span class="text-sm font-medium">Status Laporan</span>
          </div>
          <Calendar panelClass="text-xs" :manualInput="false" class="md:w-4/12 w-6/12" v-model="tanggalLaporan" view="date" dateFormat="dd MM yy" inputClass="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg  block px-3 py-2" />
        </div>
        <div class="relative py-6 pb-10 pr-20 px-4 w-full bg-ekinblue rounded-xl shadow-md bg-[url('~assets/images/dashboard/card-top-right.svg')] bg-right-top bg-no-repeat">
          <div v-if="!loadingLaporan">
            <div class="md:flex w-full" v-if="statusLaporan.id_laporan_kinerja">
              <div class="flex items-center grow">
                <div class="grow border-2 rounded-full"></div>
                <div v-if="statusLaporan.status === 'Pengecekan'" class="relative w-10 h-10 bg-white rounded-full">
                  <div class="absolute text-ekinblue top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 font-semibold text-xl">1</div>
                  <span class="absolute bottom-0 left-1/2 translate-y-3/4 -translate-x-1/2 pt-3 text-xs text-ekinlightblue">Pengecekan</span>
                </div>
                <div v-else class="relative w-10 h-10 border-2 border-dashed rounded-full">
                  <div class="absolute text-white top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 font-semibold text-xl">1</div>
                  <span class="absolute bottom-0 left-1/2 translate-y-3/4 -translate-x-1/2 pt-3 text-xs text-ekinlightblue">Pengecekan</span>
                </div>
                <div class="grow border-2 rounded-full"></div>
                <div v-if="statusLaporan.status === 'Penilaian'" class="relative w-10 h-10 bg-white rounded-full">
                  <div class="absolute text-ekinblue top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 font-semibold text-xl">2</div>
                  <span class="absolute bottom-0 left-1/2 translate-y-3/4 -translate-x-1/2 pt-4 text-xs text-ekinlightblue">Penilaian</span>
                </div>
                <div v-else class="relative w-10 h-10 border-2 border-dashed rounded-full">
                  <div class="absolute text-white top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 font-semibold text-xl">2</div>
                  <span class="absolute bottom-0 left-1/2 translate-y-3/4 -translate-x-1/2 pt-4 text-xs text-ekinlightblue">Penilaian</span>
                </div>
                <div class="grow border-2 rounded-full"></div>
                <div v-if="statusLaporan.status === 'Perhitungan'" class="relative w-10 h-10 bg-white rounded-full">
                  <div class="absolute text-ekinblue top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 font-semibold text-xl">3</div>
                  <span class="absolute bottom-0 left-1/2 translate-y-3/4 -translate-x-1/2 pt-3 text-xs text-ekinlightblue">Perhitungan</span>
                </div>
                <div v-else class="relative w-10 h-10 border-2 border-dashed rounded-full">
                  <div class="absolute text-white top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 font-semibold text-xl">3</div>
                  <span class="absolute bottom-0 left-1/2 translate-y-3/4 -translate-x-1/2 pt-4 text-xs text-ekinlightblue">Perhitungan</span>
                </div>
                <div class="grow border-2 rounded-full"></div>
              </div>
              <router-link :to="{ name: 'laporan-kinerja-detail', params: { id: statusLaporan.id_laporan_kinerja, tanggal: tanggalLaporan } }" class="hidden md:flex ml-4">
                <button type="button" class="py-2 px-5 text-xs font-medium text-ekinblue focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-ekinblue focus:z-10 focus:ring-4 focus:ring-gray-200">Lihat</button>
              </router-link>
              <router-link :to="{ name: 'laporan-kinerja-detail', params: { id: statusLaporan.id_laporan_kinerja, tanggal: tanggalLaporan } }" class="flex md:hidden absolute bottom-4 right-4 text-white cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                </svg>
              </router-link>
            </div>
            <div v-else>
              <span class="text-white text-sm">Anda belum melakukan pelaporan dihari ini!</span>
            </div>
          </div>
          <div v-else>
            <div class="w-full animate-pulse">
              <div class="h-1.5 bg-gray-100 rounded-full max-w-[360px] mb-3"></div>
              <div class="h-1.5 bg-gray-100 rounded-full max-w-[260px] mb-2.5"></div>
              <div class="h-1.5 bg-gray-100 rounded-full max-w-[260px]"></div>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center">
          <div class="md:w-3/12 w-6/12">
            <span class="text-sm font-medium">Penilaian Laporan</span>
          </div>
          <Calendar panelClass="text-xs" :manualInput="false" class="md:w-4/12 w-6/12" v-model="bulanPenilaian" view="month" dateFormat="MM" inputClass="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-3 py-2" />
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-2">
          <div class="block py-4 px-3 space-y-2 bg-ekinblue rounded-xl text-white shadow-md bg-[url('~assets/images/dashboard/card-top-right.svg')] bg-right-top bg-no-repeat">
            <span class="text-sm font-medium">Masuk</span>
            <div class="flex space-x-2">
              <span v-if="!loadingDataLaporan" class="text-3xl font-bold">{{ penilaianLaporan.masuk }}</span>
              <span v-else>
                <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </span>
              <div class="flex flex-col justify-end">
                <span class="text-xs font-normal">Laporan</span>
              </div>
            </div>
          </div>
          <div class="block py-4 px-3 space-y-2 bg-ekinblue rounded-xl text-white shadow-md bg-[url('~assets/images/dashboard/card-top-right.svg')] bg-right-top bg-no-repeat">
            <span class="text-sm font-medium">Diterima</span>
            <div class="flex space-x-2">
              <span v-if="!loadingDataLaporan" class="text-3xl font-bold">{{ penilaianLaporan.terima }}</span>
              <span v-else>
                <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </span>
              <div class="flex flex-col justify-end">
                <span class="text-xs font-normal">Laporan</span>
              </div>
            </div>
          </div>
          <div class="block py-4 px-3 space-y-2 bg-ekinblue rounded-xl text-white shadow-md bg-[url('~assets/images/dashboard/card-top-right.svg')] bg-right-top bg-no-repeat">
            <span class="text-sm font-medium">Ditolak</span>
            <div class="flex space-x-2">
              <span v-if="!loadingDataLaporan" class="text-3xl font-bold">{{ penilaianLaporan.tolak }}</span>
              <span v-else>
                <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </span>
              <div class="flex flex-col justify-end">
                <span class="text-xs font-normal">Laporan</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center items-cente">
          <div class="md:w-3/12 w-6/12">
            <span class="text-sm font-medium">Grafik Jumlah TPP</span>
          </div>

          <Calendar panelClass="text-xs" :manualInput="false" class="md:w-4/12 w-6/12" v-model="tahunTPP" view="year" dateFormat="yy" inputClass="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-3 py-2" />
        </div>
        <div class="bg-white p-4 min-h-64">
          <VueApexCharts v-if="!loadingGrafikTpp" :key="render" width="100%" type="bar" :options="tppOptions" :series="tpp"></VueApexCharts>
          <div v-else role="status" class="w-full p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700">
            <div class="h-2.5 bg-gray-200 rounded-full w-32 mb-2.5"></div>
            <div class="w-48 h-2 mb-10 bg-gray-200 rounded-full"></div>
            <div class="flex items-baseline mt-4 space-x-6">
              <div class="w-full bg-gray-200 rounded-t-full h-72"></div>
              <div class="w-full h-56 bg-gray-200 rounded-t-full"></div>
              <div class="w-full bg-gray-200 rounded-t-full h-72"></div>
              <div class="w-full h-64 bg-gray-200 rounded-t-full"></div>
              <div class="w-full bg-gray-200 rounded-t-full h-80"></div>
              <div class="w-full bg-gray-200 rounded-t-full h-72"></div>
              <div class="w-full bg-gray-200 rounded-t-full h-80 md:flex hidden"></div>
              <div class="w-full bg-gray-200 rounded-t-full h-72 md:flex hidden"></div>
              <div class="w-full bg-gray-200 rounded-t-full h-80 md:flex hidden"></div>
              <div class="w-full bg-gray-200 rounded-t-full h-72 md:flex hidden"></div>
            </div>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="flex justify-between items-center items-cente">
          <div class="md:w-3/12 w-6/12">
            <span class="text-sm font-medium">Anjab Terbanyak</span>
          </div>
          <Calendar panelClass="text-xs" :manualInput="false" class="md:w-4/12 w-6/12" v-model="bulanAnjab" view="month" dateFormat="MM" inputClass="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-3 py-2" />
        </div>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table v-if="!loadingTopAnjab" class="w-full text-xs text-left bg-white">
            <tbody>
              <tr v-if="anjabEntry.length > 0" v-for="(ajb, idx) in anjabEntry" class="border-b hover:bg-gray-50">
                <td class="px-4 py-2 w-20">
                  <div class="relative">
                    <img src="@/assets/images/dashboard/most-entry.svg" alt="" />
                    <span class="absolute text-base top-1/2 left-1/2 right-2 -translate-x-1/2 -translate-y-1/2 font-bold text-ekinblue">{{ idx + 1 }}</span>
                  </div>
                </td>
                <td class="px-4 py-2">{{ ajb.anjab }}</td>
              </tr>
              <tr v-else>
                <td class="text-center px-4 py-2">Belum ada data!</td>
              </tr>
            </tbody>
          </table>
          <div v-else role="status" class="w-full p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
            <div class="flex items-center justify-between">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full w-12"></div>
            </div>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="flex justify-center">
          <span class="text-xs text-ekingray">© Aistech Solution. 2022</span>
        </div>
      </div>
    </div>
    <div class="col-span-12 md:col-span-4 bg-white px-8 pt-5 border-l order-1 md:order-2">
      <div class="flex flex-col space-y-3">
        <span class="text-ekinblue text-sm font-medium">Selamat Datang!</span>
        <div class="mx-auto relative w-12 h-12 bg-ekinblue rounded-full">
          <svg class="absolute w-6 h-6 left-0 top-0 translate-y-1/2 translate-x-1/2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
          </svg>
        </div>
        <p class="text-sm text-center font-medium">{{ nama }}</p>
        <p v-if="nip" class="text-xs text-center font-medium">NIP. {{ nip }}</p>
        <router-link v-if="opd" class="text-xs text-center font-medium text-gray-500 hover:text-ekinblue" :to="{ name: 'struktur', params: { id: id_opd } }">
          <a role="button">{{ opd }}</a>
        </router-link>
        <p v-if="jabatan" class="text-xs text-center font-medium text-gray-500">{{ jabatan }}</p>
        <div class="mx-auto space-x-2">
          <router-link :to="{ name: 'biodata' }">
            <button type="button" class="text-white bg-ekinblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-2 focus:outline-none">Lihat Profil</button>
          </router-link>
          <router-link :to="{ name: 'password' }">
            <button type="button" class="text-ekinblue bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-md border border-blue-200 text-xs font-medium px-3 py-2 hover:text-blue-700">Ganti Password</button>
          </router-link>
        </div>
        <!--<div class="px-10 py-5">
          <hr />
        </div>
       <div class="flex justify-between items-center">
          <span class="text-sm font-medium">Rekap Absensi</span>
          <Calendar panelClass="text-xs" :manualInput="false" v-model="bulanAbsen" view="month" dateFormat="MM" inputClass="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-3 py-2" />
        </div>
        <div class="bg-white min-h-40">
          <VueApexCharts :key="render" width="100%" type="bar" :options="absensiOptions" :series="absensi"></VueApexCharts>
        </div> -->
        <div class="px-10 py-5">
          <hr />
        </div>
        <div class="flex justify-between items-center">
          <span class="text-sm font-medium">Penginputan Anjab</span>
          <Calendar panelClass="text-xs" :manualInput="false" v-model="bulanAnjabInput" view="month" dateFormat="MM" inputClass="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-3 py-2" />
        </div>
        <div class="bg-white min-h-40 pb-5">
          <VueApexCharts v-if="!loadingAnjab" :key="render" width="100%" :options="anjabOptions" :series="anjab"></VueApexCharts>
          <div v-else role="status" class="flex items-center justify-center h-56 w-full bg-gray-300 rounded-lg animate-pulse">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="text-white w-12 h-12">
              <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z" clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sticky bottom-4 text-ekinblue drop-shadow-md">
    <div class="flex items-center h-100 justify-end mr-3">
      <div class="opacity-0 hover:opacity-100 duration-200 ease-in h-10 hover:bg-white text-xs rounded-full text-ekinblue px-4 py-auto flex items-center"><span>Download Manual Book</span></div>
      <svg @click="manual" class="hover:scale-105 duration-300 ease-in-out cursor-pointer w-10 h-10" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path>
      </svg>
    </div>
  </div>
  <!-- </div> -->
  <!-- <div v-else>
    <div class="flex justify-center py-7">
      <div role="status">
        <svg aria-hidden="true" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div> -->
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import { onBeforeMount, onMounted, ref, watch } from "vue";
import Calendar from "primevue/calendar";

export default {
  components: {
    VueApexCharts,
    Calendar,
  },
  setup() {
    const loadingLaporan = ref(true);
    const loadingDataLaporan = ref(true);
    const loadingGrafikTpp = ref(true);
    const loadingTopAnjab = ref(true);
    const loadingAnjab = ref(true);
    const nama = ref(window.localStorage.getItem("nama"));
    const nip = ref(window.localStorage.getItem("nip"));
    const jabatan = ref("");
    const tanggalLaporan = ref(new Date());
    const bulanAbsen = ref(new Date());
    const bulanPenilaian = ref(new Date());
    const tahunTPP = ref(new Date());
    const bulanAnjab = ref(new Date());
    const bulanAnjabInput = ref(new Date());
    const opd = ref("");
    const role = ref(window.localStorage.getItem("role"));
    const id_opd = ref(window.localStorage.getItem("id_opd"));
    const mountChart = ref(false);
    const render = ref(0);
    const tppOptions = ref({
      chart: {
        id: "tpp",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: ["JAN", "FEB", "MAR", "APR", "MEI", "JUN", "JUL", "AGT", "SEP", "OKT", "NOV", "DES"],
      },
      yaxis: {
        labels: {
          show: true,
          formatter: (value) => {
            return `${(value / 1000000).toFixed(1)} Juta`;
          },
        },
      },
      colors: ["#3c5185"],
      dataLabels: {
        enabled: false,
      },
      plotOptions: { bar: { borderRadius: 10, columnWidth: "45%" } },
    });
    const absensiOptions = ref({
      chart: {
        id: "absensi",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: true,
        },
        categories: ["On-Time", "Terlambat", "Izin", "Sakit"],
      },
      colors: ["#04C41A", "#FFC700", "#344CB7", "#F13838"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        showForSingleSeries: true,
      },
      yaxis: {
        show: false,
      },
      plotOptions: { bar: { borderRadius: 2, horizontal: true } },
    });
    const anjabOptions = ref({
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Belum Selesai", "Selesai"],
      colors: ["#3c5185", "#398AB9"],
      legend: {
        position: "bottom",
      },
      dataLabels: {
        enabled: false,
      },
    });
    const anjab = ref([60, 40]);
    const tpp = ref([
      {
        name: "Penerimaan TPP per bulan",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ]);
    const absensi = ref([
      {
        name: "On-Time",
        data: [0],
      },
      {
        name: "Terlambat",
        data: [0],
      },
      {
        name: "Cepat Pulang",
        data: [0],
      },
      {
        name: "Izin/Sakit",
        data: [0],
      },
    ]);
    const statusLaporan = ref({});
    const penilaianLaporan = ref({ masuk: "0", terima: "0", tolak: "0" });
    const today = ref(new Date());
    const anjabEntry = ref([]);

    onBeforeMount(async () => {
      try {
        const inputAnjab = await getInputAnjab();
        const statuslporan = await getStatusPegawai();
        const penilaian = await getLaporanBulan();
        const mostAnjab = await getMostAnjab();
        const pegawai = await getPegawai();
        // const absen = await getAbsensi();
        const tppbulan = await getTahunTpp();
      } catch {
        console.log("err");
      }
    });

    watch(bulanAnjab, (anjab) => getMostAnjab());
    watch(bulanAnjabInput, (anjab) => getInputAnjab());
    watch(bulanAbsen, (absen) => getAbsensi());
    watch(tanggalLaporan, (tglapor) => getStatusPegawai());
    watch(bulanPenilaian, (nilai) => getLaporanBulan());
    watch(tahunTPP, (tahun) => getTahunTpp());

    const getLaporanBulan = async () => {
      loadingDataLaporan.value = true;
      const laporanMasuk = await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/laporanMasukPegawai/${window.localStorage.getItem("id_pegawai")}/${bulanPenilaian.value.getMonth() + 1}`, { credentials: "include" });
      const laporanTerima = await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/laporanTerimaByIdPegawai/${window.localStorage.getItem("id_pegawai")}/${bulanPenilaian.value.getMonth() + 1}`, { credentials: "include" });
      const laporanTolak = await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/laporanTolakByIdPegawai/${window.localStorage.getItem("id_pegawai")}/${bulanPenilaian.value.getMonth() + 1}`, { credentials: "include" });
      const laporanMasukRes = await laporanMasuk.json();
      const laporanTerimaRes = await laporanTerima.json();
      const laporanTolakRes = await laporanTolak.json();
      penilaianLaporan.value = { masuk: laporanMasukRes.data[0].laporan_masuk, terima: laporanTerimaRes.data[0].laporan_terima, tolak: laporanTolakRes.data[0].laporan_tolak };
      loadingDataLaporan.value = false;

      return 0;
    };

    const getTahunTpp = async () => {
      loadingGrafikTpp.value = true;
      tpp.value = [
        {
          name: "Penerimaan TPP per bulan",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ];
      for (let i = tahunTPP.value.getMonth() + 1; i >= 1; i--) {
        const gettpp = await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/laporanTppByIdPegawai/${window.localStorage.getItem("id_pegawai")}/${tahunTPP.value.getFullYear()}/${i}`, { credentials: "include" });
        const result = await gettpp.json();
        // result.data.forEach((element, x) => {
        //   element ? (tpp.value[0].data[x] = element) : (tpp.value[0].data[x] = 0);
        // });
        tpp.value[0].data[i-1] = result.data ? result.data : 0;
        if (i === tahunTPP.value.getMonth() + 1) loadingGrafikTpp.value = false;
      }
      render.value += 1;
      return 0;
    };

    const getAbsensi = async () => {
      const absen = await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/rekapAbsensi/${window.localStorage.getItem("id_pegawai")}/${bulanAbsen.value.getMonth() + 1}`, { credentials: "include" });
      const result = await absen.json();
      result.data[0].on_time ? (absensi.value[0].data[0] = result.data[0].on_time) : (absensi.value[0].data[0] = 0);
      result.data[0].terlambat ? (absensi.value[1].data[0] = result.data[0].terlambat) : (absensi.value[1].data[0] = 0);
      result.data[0].cepat_pulang ? (absensi.value[2].data[0] = result.data[0].cepat_pulang) : (absensi.value[2].data[0] = 0);
      result.data[0].cuti_izin_sakit ? (absensi.value[3].data[0] = result.data[0].cuti_izin_sakit) : (absensi.value[3].data[0] = 0);
      return 0;
    };
    const getInputAnjab = async () => {
      loadingAnjab.value = true;
      const anjabInput = await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/inputAnjabByIdPegawai/${window.localStorage.getItem("id_pegawai")}/${bulanAnjabInput.value.getMonth() + 1}/${bulanAnjabInput.value.getFullYear()}`, { credentials: "include" });
      const result = await anjabInput.json();
      result.data[0].selesai ? (anjab.value[0] = +result.data[0].selesai) : (anjab.value[0] = 0);
      result.data[0].belum_selesai ? (anjab.value[1] = +result.data[0].belum_selesai) : (anjab.value[1] = 0);
      render.value += 1;
      loadingAnjab.value = false;

      return 0;
    };
    const getMostAnjab = async () => {
      loadingTopAnjab.value = true;
      const mostAnjab = await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/mostEntryAnjabByIdPegawai/${window.localStorage.getItem("id_pegawai")}/${bulanAnjab.value.getMonth() + 1}/${bulanAnjab.value.getFullYear()}`, { credentials: "include" });
      const result = await mostAnjab.json();
      anjabEntry.value = result.data;
      loadingTopAnjab.value = false;

      return 0;
    };

    const getPegawai = async () => {
      const pegawai = await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/pegawai/${window.localStorage.getItem("id_pegawai")}`, { credentials: "include" });
      const result = await pegawai.json();
      nama.value = result.data[0].nama;
      nip.value = result.data[0].nip;
      jabatan.value = result.data[0].jabatan;
      opd.value = result.data[0].opd;
      return 0;
    };

    const getStatusPegawai = async () => {
      const requestOptions = {
        method: "GET",
        credentials: "include",
      };
      loadingLaporan.value = true;
      const pegawai = await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/statusLaporanPegawai/${tanggalLaporan.value.getFullYear()}-${tanggalLaporan.value.getMonth() + 1}-${tanggalLaporan.value.getDate()}/${window.localStorage.getItem("id_pegawai")}`, requestOptions);
      const result = await pegawai.json();
      statusLaporan.value = result.data;
      loadingLaporan.value = false;

      return 0;
    };

    const manual = () => {
      window.open("/ManualBook.zip", "_blank");
    };
    return {
      loadingLaporan,
      loadingDataLaporan,
      loadingTopAnjab,
      loadingAnjab,
      penilaianLaporan,
      manual,
      statusLaporan,
      bulanAnjab,
      bulanAnjabInput,
      tahunTPP,
      bulanAbsen,
      bulanPenilaian,
      tanggalLaporan,
      nama,
      jabatan,
      nip,
      tppOptions,
      render,
      mountChart,
      absensi,
      absensiOptions,
      tpp,
      anjab,
      anjabOptions,
      anjabEntry,
      today,
      role,
      opd,
      id_opd,
      loadingGrafikTpp,
    };
  },
};
</script>

<style></style>

