<template>
  <section id="thirdpage" class="bg-ekinlightblue scroll-smooth px-5 md:px-10">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 container mx-auto pt-32 md:py-0">
      <div class="flex flex-row items-center text-right mx-auto">
        <div v-animate-onscroll="'animate__animated animate__fadeInLeft'">
          <h1 class="text-xl md:text-5xl font-semibold text-ekinblue border-ekinblue">Produk Hukum</h1>
        </div>
      </div>
      <div class="flex flex-col">
        <div v-if="showProduk" v-animate-onscroll="'animate__animated animate__fadeInRight'">
          <Carousel v-if="produk.length > 0" :key="produk" :value="produk" :numVisible="3" :numScroll="3" orientation="vertical" containerClass="w-full" indicatorsContentClass="rounded-full">
            <template #item="slotProps">
              <div class="flex flex-col px-6 py-3 mb-3 bg-white hover:bg-light rounded-lg drop-shadow-md">
                <p class="font-medium text-lg text-black text-justify leading-9">
                  {{ slotProps.data.judul }}
                </p>
                <div class="text-gray-400 text-sm font-medium leading-9">
                  <span>{{ slotProps.data.kategori }} | </span>
                  <span>{{ slotProps.data.nomor }}</span>
                </div>
                <div class="flex justify-between text-ekinblue text-sm font-medium">
                  <div class="text-gray-400 text-sm font-medium leading-9">
                    <span>{{ new Intl.DateTimeFormat("id", { dateStyle: "full" }).format(new Date(slotProps.data.tgl_input)) }}</span>
                  </div>
                  <div class="flex space-x-3">
                    <button @click="downloadHandler(slotProps.data.file)" class="flex space-x-2 items-center">
                      <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
                      </svg>
                      <span>Download</span>
                    </button>
                  </div>
                </div>
              </div>
            </template>
          </Carousel>
          <div v-else class="block py-12 md:py-24 px-5">
            <div class="flex justify-center space-x-10 items-center">
              <img src="@/assets/images/illustrations/oc-browse.svg" alt="" class="md:w-2/12 w-3/12" />
              <div class="flex flex-col md:w-5/12 w-9/12 text-ekinblue">
                <span class="text-lg font-bold">Data kosong!</span>
                <span class="">Produk Hukum tidak ditemukan!</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="flex flex-col py-10">
          <div class="flex my-auto">
            <button disabled type="button" class="py-2.5 px-5 mx-auto text-xs font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 inline-flex items-center">
              <svg aria-hidden="true" role="status" class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="#1C64F2"
                />
              </svg>
              Loading...
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import Carousel from "primevue/carousel";
export default {
  components: {
    Carousel,
  },
  setup() {
    const produk = ref([]);
    const showProduk = ref(false);
    onBeforeMount(() => {
      getProduk();
    });

    function getProduk() {
      Promise.all([fetch(`${process.env.VUE_APP_ENDPOINT}/produk-hukum/api/V1/getProdukHukum/public`)])
        .then(async ([data]) => {
          const result = await data.json();
          produk.value = result.data;
          showProduk.value = true;
        })
        .catch((err) => console.log(err));
    }

    function downloadHandler(dokumen) {
      window.open(`${process.env.VUE_APP_ENDPOINT}/produkHukum/${dokumen}`);
    }
    return { produk, showProduk, downloadHandler };
  },
};
</script>

<style scoped>
:deep() .p-carousel-items-content {
  height: 424px !important;
}

@media (max-width: 640px) {
  :deep() .p-carousel-items-content {
    height: 426px !important;
  }
}

:deep() .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #3c5185 !important;
}
</style>
