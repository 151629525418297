<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import { computed } from "vue";
import {useRouter} from 'vue-router'
import Blank from "./components/layout/Blank.vue";
import Dashboard from "./components/layout/Dashboard.vue";

export default {
  setup() {
    const resolveLayout = computed(() => {
      // Handles initial route
      if (useRouter().currentRoute.value.name === null) return null;
      if (useRouter().currentRoute.value.meta.layout === "blank") return Blank;
      return Dashboard;
    });

    return {
      resolveLayout
    };
  },
};
</script>
