export const aksesHandler = async (to) => {
  let role = window.localStorage.getItem("role");
  if (role) {
    const response = await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/menu/akses/sidebar/${role}`, { credentials: "include" });
    const menu = await response.json();
    if (to.meta.menu) return menu.data.filter((item) => (item.url === to.meta.menu) && item.view);
  }
  return [];
};


