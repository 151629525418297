<template>
  <div class="h-min-screen h-full">
    <div class="grid grid-cols-1 md:grid-cols-2 h-full">
      <div class="flex flex-col bg-ekinblue py-auto space-y-5 md:space-y-3 py-14 md:py-20">
        <div class="flex">
          <div class="mt-auto p md:pr-4">
            <img width="100" height="100" src="@/assets/images/landingpage/mockup_2.png" class="w-3/12 md:w-4/12 ml-auto" />
          </div>
          <div class="pr-10 pb-5">
            <img width="100" height="100" src="@/assets/images/landingpage/mockup_3.png" class="w-3/12 md:w-6/12" />
          </div>
        </div>
        <div class="flex justify-center">
          <img width="100" height="100" src="@/assets/images/landingpage/mockup.png" class="w-4/12 md:w-7/12" />
        </div>
        <div class="flex flex-col items-center">
          <span class="text-lg md:text-lg text-ekinlightblue font-normal pt-20 md:pt-10 text-center"
            >Aplikasi berbasis web untuk <br />
            melakukan pelaporan dan penilaian <br />
            kinerja pegawai</span
          >
        </div>
      </div>
      <div class="flex flex-col space-y-2 px-5 md:px-0">
        <span class="text-xl md:text-3xl text-ekindarklightblue font-medium mx-auto pt-10 md:pt-40">E-KINERJA</span>
        <span class="text-lg md:text-3xl text-ekinblue font-semibold mx-auto">Selamat Datang!</span>
        <span class="text-base text-ekingray font-normal mx-auto px-10 text-center"
          >Dengan E-Kinerja akan membantu penilaian secara <br />
          akurat dan juga tampilan yang user friendly</span
        >
        <div class="md:px-20 space-y-4 py-4">
          <!-- username -->
          <Transition name="custom-classes" enter-active-class="animate__animated animate__fadeIn animate__faster" leave-active-class="animate__animated animate__fadeOut animate__faster">
            <Danger :key="alertData" v-show="alert" :data="alertData" />
          </Transition>
          <div v-if="logoutStatus" class="flex p-4 text-xs text-gray-700 bg-gray-100 rounded-lg justify-between" role="alert">
            <div class="flex">
              <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path>
              </svg>
              <span class="sr-only">Info</span>
              <div>Anda telah berhasil logout dari aplikasi!</div>
            </div>
          </div>
          <div class="flex p-4 text-xs text-gray-700 bg-gray-100 rounded-lg justify-between" role="alert">
            <div class="flex items-center">
              <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path>
              </svg>
              <span class="sr-only">Info</span>
              <div> Pengumuman! akses aplikasi EKIN mulai 6 Februari 2023 akan berpindah ke 
                <a class="font-bold" href="https://ekin.banggaikab.go.id">https://ekin.banggaikab.go.id</a>
              </div>
            </div>
          </div>
          <div class="relative">
            <input @keyup.enter="login" v-model="username" type="text" :class="emptyusername ? 'border-red-500 border-2 !text-red-500' : 'border-gray-300 border-1'" class="block p-4 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border" placeholder="Username" />
          </div>
          <div v-if="emptyusername" class="text-red-500 text-xs flex space-x-2">
            <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg
            ><span> Username tidak boleh kosong! </span>
          </div>
          <Password
            @keyup.enter="login"
            v-model="password"
            toggleMask
            :feedback="false"
            placeholder="Password"
            class="w-full"
            :inputClass="emptypass ? 'border-red-500 border-2 !text-red-500 block p-4 w-full text-sm text-gray-900 bg-gray-50 rounded-lg' : 'border-gray-300 border-1 block p-4 w-full text-sm text-gray-900 bg-gray-50 rounded-lg'"
          >
            ></Password
          >
          <div v-if="emptypass" class="text-red-500 text-xs flex space-x-2">
            <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg
            ><span> Password tidak boleh kosong! </span>
          </div>
          <div class="flex flex-col items-center justify-center">
            <button @click="login" class="text-sm py-3 w-full rounded bg-ekinblue text-white text-center">
              <span v-if="!load">Login</span>
              <div v-else role="status">
                <svg class="inline mr-2 w-4 h-4 text-gray-200 animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </div>
          <div class="flex justify-center">
            <router-link :to="{ name: 'lupa-password' }">
              <a role="button" class="text-sm font-thin text-ekinblue">Lupa Password</a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import Danger from "../../../components/admin/alerts/Danger.vue";
import { useCookies } from "vue3-cookies";
import Password from "primevue/password";
export default {
  components: {
    Danger,
    Password,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { cookies } = useCookies();
    const load = ref(false);
    const username = ref("");
    const password = ref("");
    const emptyusername = ref(false);
    const emptypass = ref(false);
    const alert = ref(false);
    const alertData = ref();
    const logoutStatus = ref(false);

    onMounted(() => {
      if (route.query.stat) logoutStatus.value = true;
      setTimeout(() => {
        logoutStatus.value = false;
      }, 5000);
    });
    const login = async () => {
      load.value = true;
      emptyusername.value = false;
      emptypass.value = false;
      alert.value = false;
      if (!username.value.length > 0) {
        emptyusername.value = true;
        load.value = false;
      } else if (!password.value.length > 0) {
        emptypass.value = true;
        load.value = false;
      } else {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify({
            username: username.value,
            password: password.value,
          }),
        };
        try {
          const request = await fetch(`${process.env.VUE_APP_ENDPOINT}/auth/login`, requestOptions);
          const result = await request.json();
          if (result.status) {
            Object.keys(result.data).forEach((k, i) => {
              window.localStorage.setItem(k, result.data[k]);
              if (k == "id_opd" && result.data[k]) getOpd(result.data[k]);
            });
            cookies.set("cookies", result.data.id);
            router.push({ name: "dashboard", query: route.query });
          } else {
            triggerAlert({ issue: "Username atau Password", msg: " salah! silahkan coba lagi" });
          }
          load.value = false;
        } catch {
          load.value = false;
        }
      }
    };

    const getOpd = (id) => {
      console.log(id);
      const requestOptions = {
        credentials: "include",
      };
      fetch(`${process.env.VUE_APP_ENDPOINT}/api/V1/opd/${id}`, requestOptions).then(async (dataopd) => {
        const result = await dataopd.json();
        if (result.success) {
          window.localStorage.setItem("opd", result.data.nama);
        }
      });
    };

    function triggerAlert(msg) {
      alertData.value = msg;
      alert.value = true;
      setTimeout(function () {
        alert.value = false;
      }, 10000);
    }
    return { logoutStatus, load, username, password, emptyusername, emptypass, login, alert, alertData };
  },
};
</script>

<style scoped>
:deep() .p-input-icon-right > i:last-of-type {
  top: 0;
  right: 5%;
  transform: translate(50%, 50%);
  color: #71717a;
}
:deep() .pi {
  position: absolute;
  top: 25% !important;
  color: #71717a;
}
</style>
