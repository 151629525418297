<template>
  <section id="firstpage" class="relative bg-ekinblue">
    <Carousel v-if="showCarousel" :key="0" :value="slides" :numScroll="1" :circular="true" :autoplayInterval="2000">
      <template #item="slotProps">
        <div>
          <div class="relative h-screen">
            <img :src="slotProps.data.foto" class="object-cover h-full md:h-auto" />
            <div class="absolute top-0 bottom-8 bg-black/30 w-full"></div>
          </div>
        </div>
      </template>
    </Carousel>
    <div class="absolute top-0 translate-y-1/2 left-0 right-0 space-y-8">
      <div class="text-white text-xl md:text-5xl font-semibold text-center flex flex-col mb-auto animate__animated animate__fadeInDown">
        <span>Penilaian Pegawai</span>
        <span>Dengan E-Kinerja</span>
      </div>
      <div class="text-center text-ekinlightblue animate__animated animate__fadeIn animate__delay-1s">
        <span class="font-normal text-lg md:text-xl"
          >Melakukan Penilaian Secara Mudah <br class="md:hidden" />
          dan Akurat dengan Tampilan User Friendly</span
        >
      </div>
      <div class="text-center animate__animated animate__zoomIn animate__delay-1s">
        <button class="bg-transparent hover:bg-lightblue-ekin text-white font-semibold hover:bg-blue-900 hover:border-0 hover:text-white px-14 py-4 border border-lightblue-ekin rounded-full">Get started</button>
      </div>
    </div>
  </section>
</template>

<script>
import Carousel from "primevue/carousel";
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
export default {
  components: {
    Carousel,
  },
  setup() {
    const showCarousel = ref(false);
    const slides = ref([
      { foto: "https://images.pexels.com/photos/2559941/pexels-photo-2559941.jpeg?cs=srgb&dl=pexels-roberto-nickson-2559941.jpg&fm=jpg&_gl=1*4qnpn9*_ga*Nzc3ODcwNDY3LjE2Njc1NDQ0NzM.*_ga_8JE65Q40S6*MTY2NzU0NDQ3Ny4xLjEuMTY2NzU0NDUxOS4wLjAuMA.." },
      { foto: "https://images.pexels.com/photos/2559941/pexels-photo-2559941.jpeg?cs=srgb&dl=pexels-roberto-nickson-2559941.jpg&fm=jpg&_gl=1*4qnpn9*_ga*Nzc3ODcwNDY3LjE2Njc1NDQ0NzM.*_ga_8JE65Q40S6*MTY2NzU0NDQ3Ny4xLjEuMTY2NzU0NDUxOS4wLjAuMA.." },
      { foto: "https://images.pexels.com/photos/2559941/pexels-photo-2559941.jpeg?cs=srgb&dl=pexels-roberto-nickson-2559941.jpg&fm=jpg&_gl=1*4qnpn9*_ga*Nzc3ODcwNDY3LjE2Njc1NDQ0NzM.*_ga_8JE65Q40S6*MTY2NzU0NDQ3Ny4xLjEuMTY2NzU0NDUxOS4wLjAuMA.." },
    ]);
    onMounted(() => {
      showCarousel.value = true;
    });
    return { showCarousel, slides };
  },
};
</script>

<style scoped>
:deep() .p-carousel-prev,
:deep() .p-carousel-next {
  display: none !important;
}
:deep() .p-carousel-indicators {
  margin-top: -80px;
  z-index: 20;
}
:deep() .p-carousel-content {
  overflow: hidden;
}
</style>
