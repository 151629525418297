<template>
  <section id="secondpage" class="bg-[url('~assets/images/landingpage/sec2.svg')] bg-top bg-no-repeat bg-contain">
    <div class="container mx-auto py-10 space-y-10 px-5 md:px-10">
      <div v-animate-onscroll="'animate__animated animate__fadeInDown'">
        <div class="text-ekinblue text-xl md:text-5xl font-semibold text-center md:pb-5 pt-32 md:pt-48 leading-tight flex flex-col mb-auto" data-aos="fade-down">
          <span>Apa saja yang dapat dilakukan</span>
          <span>dengan E-Kinerja</span>
        </div>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 gap-4 mb-24">
        <div v-animate-onscroll="'animate__animated animate__fadeInUp'">
          <div class="flex flex-col p-6 bg-transparent hover:bg-light items-center space-y-4">
            <img src="@/assets/images/landingpage/pelaporan.svg" class="w-3/5" />
            <h5 class="mb-2 font-semibold tracking-tight text-ekinblue text:-lg md:text-4xl">Pelaporan</h5>
            <p class="font-medium text-base md:text-md text-gray-400 text-justify">Pegawai Melakukan Pelaporan Kinerja secara Daily sesuai format yang Telah disediakan</p>
          </div>
        </div>
        <div v-animate-onscroll="'animate__animated animate__fadeInUp'">
          <div class="flex flex-col p-6 bg-transparent hover:bg-light items-center space-y-4">
            <img src="@/assets/images/landingpage/pemeriksaan.svg" class="w-3/5" />
            <h5 class="mb-2 font-semibold tracking-tight text-ekinblue text:-lg md:text-4xl">Pemeriksaan</h5>
            <p class="font-medium text-base md:text-md text-gray-400 text-justify">Pimpinan akan melakukan pemeriksaan terhadapan laporan kinerja pegawai yang masuk</p>
          </div>
        </div>
        <div v-animate-onscroll="'animate__animated animate__fadeInUp'">
          <div class="flex flex-col p-6 bg-transparent hover:bg-light items-center space-y-4">
            <img src="@/assets/images/landingpage/penilaian.svg" class="w-3/5" />
            <h5 class="mb-2 font-semibold tracking-tight text-ekinblue text:-lg md:text-4xl">Penilaian</h5>
            <p class="font-medium text-base md:text-md text-gray-400 text-justify">Pimpinan akan Memberikan Penilaian terhadap pelaporan Kinerja Pegawai yang telah diinputkan</p>
          </div>
        </div>
        <div v-animate-onscroll="'animate__animated animate__fadeInUp'">
          <div class="flex flex-col p-6 bg-transparent hover:bg-light items-center space-y-4">
            <img src="@/assets/images/landingpage/perhitungan.svg" class="w-3/5" />
            <h5 class="mb-2 font-semibold tracking-tight text-ekinblue text:-lg md:text-4xl">Perhitungan</h5>
            <p class="font-medium text-base md:text-md text-gray-400 text-justify">Aplikasi E-Kinerja akan melakukan perhitungan nilai pelaporan kinerja pegawai yang telah diberikan oleh pimpinan</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped></style>
