import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueAnimateOnScroll from "vue3-animate-onscroll";
import PrimeVue from "primevue/config";
import Notifications from "notiwind";
import { globalCookiesConfig } from "vue3-cookies";
import vSelect from "vue-select";
import "flowbite";
import "animate.css";
import "./index.css";
globalCookiesConfig({
  expireTimes: "2h",
  secure: false,
  sameSite: "None",
});

const app = createApp(App)
  .use(VueAnimateOnScroll)
  .use(router)
  .component('v-select',vSelect)
  .use(PrimeVue, {
    locale: {
      startsWith: 'Starts with',
      contains: 'Contains',
      notContains: 'Not contains',
      endsWith: 'Ends with',
      equals: 'Equals',
      notEquals: 'Not equals',
      noFilter: 'No Filter',
      lt: 'Less than',
      lte: 'Less than or equal to',
      gt: 'Greater than',
      gte: 'Greater than or equal to',
      dateIs: 'Date is',
      dateIsNot: 'Date is not',
      dateBefore: 'Date is before',
      dateAfter: 'Date is after',
      clear: 'Clear',
      apply: 'Apply',
      matchAll: 'Match All',
      matchAny: 'Match Any',
      addRule: 'Add Rule',
      removeRule: 'Remove Rule',
      accept: 'Yes',
      reject: 'No',
      choose: 'Choose',
      upload: 'Upload',
      cancel: 'Cancel',
      completed: 'Completed',
      pending: 'Pending',
      dayNames: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"],
      dayNamesShort: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
      dayNamesMin: ["Mn", "Sn", "Sl", "Rb", "Km", "Jm", "Sb"],
      monthNames: ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"],
      chooseYear: 'Choose Year',
      chooseMonth: 'Choose Month',
      chooseDate: 'Choose Date',
      prevDecade: 'Previous Decade',
      nextDecade: 'Next Decade',
      prevYear: 'Previous Year',
      nextYear: 'Next Year',
      prevMonth: 'Previous Month',
      nextMonth: 'Next Month',
      prevHour: 'Previous Hour',
      nextHour: 'Next Hour',
      prevMinute: 'Previous Minute',
      nextMinute: 'Next Minute',
      prevSecond: 'Previous Second',
      nextSecond: 'Next Second',
      am: 'am',
      pm: 'pm',
      today: 'Today',
      weekHeader: 'Wk',
      firstDayOfWeek: 0,
      dateFormat: 'mm/dd/yy',
      weak: 'Weak',
      medium: 'Medium',
      strong: 'Strong',
      passwordPrompt: 'Enter a password',
      emptyFilterMessage: 'No results found', // @deprecated Use 'emptySearchMessage' option instead.
      searchMessage: '{0} results are available',
      selectionMessage: '{0} items selected',
      emptySelectionMessage: 'No selected item',
      emptySearchMessage: 'No results found',
      emptyMessage: 'No available options',
      aria: {
          trueLabel: 'True',
          falseLabel: 'False',
          nullLabel: 'Not Selected',
          star: '1 star',
          stars: '{star} stars',
          selectAll: 'All items selected',
          unselectAll: 'All items unselected',
          close: 'Close',
          previous: 'Previous',
          next: 'Next',
          navigation: 'Navigation',
          scrollTop: 'Scroll Top'
      }
    },
  })
  .use(Notifications);
app.config.globalProperties.$baseUrl = process.env.VUE_APP_ENDPOINT;
app.mount("#app");
