<template>
  <div v-if="render">
    <DashboardAdmin v-if="role === 'administrator'" />
    <DashboardPegawai v-else />
  </div>
</template>

<script>
import DashboardAdmin from "./DashboardAdministrator.vue";
import DashboardPegawai from "./DashboardPegawai.vue";
import { onMounted, ref } from "vue";
export default {
  components: {
    DashboardAdmin,
    DashboardPegawai,
  },
  setup() {
    const role = ref("");
    const render = ref(false);
    onMounted(() => {
      role.value = window.localStorage.getItem("role");
      render.value = true;
    });
    return { role, render };
  },
};
</script>

<style></style>
