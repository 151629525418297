<template>
  <div class="grid grid-cols-12 min-h-screen">
    <div class="col-span-12 md:col-span-8 bg-ekinadminlightblue p-2 order-2 md:order-1">
      <div class="p-4 rounded-lg space-y-8">
        <div class="flex justify-end">
          <select v-model="id_opd" class="shadow-sm w-full md:w-2/5 bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-3 py-2">
            <option value="">Semua OPD</option>
            <option v-for="item in daftarOpd" :value="item.id">{{ item.nama }}</option>
          </select>
        </div>
        <div class="flex justify-between items-center">
          <div class="md:w-3/12 w-6/12">
            <span class="text-sm font-medium">Penilaian Laporan</span>
          </div>
          <Calendar panelClass="text-xs" :manualInput="false" class="md:w-4/12 w-6/12" v-model="bulanPenilaian" view="month" dateFormat="MM" inputClass="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-3 py-2" />
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-2">
          <div class="block py-4 px-3 space-y-2 bg-ekinblue rounded-xl text-white shadow-md bg-[url('~assets/images/dashboard/card-top-right.svg')] bg-right-top bg-no-repeat">
            <span class="text-sm font-medium">Masuk</span>
            <div class="flex space-x-2">
              <span v-if="!loadingDataLaporan" class="text-3xl font-bold">{{ laporanMasuk?.success ? laporanMasuk.data[0].laporan_masuk : "0" }}</span>
              <span v-else>
                <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </span>
              <div class="flex flex-col justify-end">
                <span class="text-xs font-normal">Laporan</span>
              </div>
            </div>
            <div class="flex space-x-2 items-center text-ekinlightblue">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 10l7-7m0 0l7 7m-7-7v18"></path></svg>
              <span class="text-xs">{{ laporanMasuk?.success ? Number(laporanMasuk.persen).toFixed(2) : "0" }}% Pegawai</span>
            </div>
          </div>
          <div class="block py-4 px-3 space-y-2 bg-ekinblue rounded-xl text-white shadow-md bg-[url('~assets/images/dashboard/card-top-right.svg')] bg-right-top bg-no-repeat">
            <span class="text-sm font-medium">Diterima</span>
            <div class="flex space-x-2">
              <span v-if="!loadingDataLaporan" class="text-3xl font-bold">{{ laporanTerima?.success ? laporanTerima.data[0].laporan_tolak : "0" }}</span>
              <span v-else>
                <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </span>
              <div class="flex flex-col justify-end">
                <span class="text-xs font-normal">Laporan</span>
              </div>
            </div>
            <div class="flex space-x-2 items-center text-ekinlightblue">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                ></path>
              </svg>
              <span class="text-xs">{{ laporanTerima?.success ? Number(laporanTerima.persen).toFixed(2) : "0" }}% Pegawai</span>
            </div>
          </div>
          <div class="block py-4 px-3 space-y-2 bg-ekinblue rounded-xl text-white shadow-md bg-[url('~assets/images/dashboard/card-top-right.svg')] bg-right-top bg-no-repeat">
            <span class="text-sm font-medium">Ditolak</span>
            <div class="flex space-x-2">
              <span v-if="!loadingDataLaporan" class="text-3xl font-bold">{{ laporanTolak?.success ? laporanTolak.data[0].laporan_tolak : "0" }}</span>
              <span v-else>
                <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </span>
              <div class="flex flex-col justify-end">
                <span class="text-xs font-normal">Laporan</span>
              </div>
            </div>
            <div class="flex space-x-2 items-center text-ekinlightblue">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                ></path>
              </svg>
              <span class="text-xs">{{ laporanTolak?.success ? Number(laporanTolak.persen).toFixed(2) : "0" }}% Pegawai</span>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center items-cente">
          <div class="md:w-3/12 w-6/12">
            <span class="text-sm font-medium">Grafik Jumlah TPP</span>
          </div>
          <Calendar panelClass="text-xs" :manualInput="false" class="md:w-4/12 w-6/12" v-model="tahunTPP" view="year" dateFormat="yy" inputClass="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-3 py-2" />
        </div>
        <div class="bg-white p-4 min-h-64">
          <VueApexCharts v-if="!loadingGrafikTpp" :key="render" width="100%" type="bar" :options="tppOptions" :series="tpp"></VueApexCharts>
          <div v-else role="status" class="w-full p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700">
            <div class="h-2.5 bg-gray-200 rounded-full w-32 mb-2.5"></div>
            <div class="w-48 h-2 mb-10 bg-gray-200 rounded-full"></div>
            <div class="flex items-baseline mt-4 space-x-6">
              <div class="w-full bg-gray-200 rounded-t-full h-72"></div>
              <div class="w-full h-56 bg-gray-200 rounded-t-full"></div>
              <div class="w-full bg-gray-200 rounded-t-full h-72"></div>
              <div class="w-full h-64 bg-gray-200 rounded-t-full"></div>
              <div class="w-full bg-gray-200 rounded-t-full h-80"></div>
              <div class="w-full bg-gray-200 rounded-t-full h-72"></div>
              <div class="w-full bg-gray-200 rounded-t-full h-80 md:flex hidden"></div>
              <div class="w-full bg-gray-200 rounded-t-full h-72 md:flex hidden"></div>
              <div class="w-full bg-gray-200 rounded-t-full h-80 md:flex hidden"></div>
              <div class="w-full bg-gray-200 rounded-t-full h-72 md:flex hidden"></div>
            </div>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="flex justify-between items-center items-cente">
          <div class="md:w-3/12 w-6/12">
            <span class="text-sm font-medium">Anjab Terbanyak</span>
          </div>
          <Calendar panelClass="text-xs" :manualInput="false" class="md:w-4/12 w-6/12" v-model="bulanAnjab" view="month" dateFormat="MM" inputClass="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-3 py-2" />
        </div>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table v-if="!loadingTopAnjab" class="w-full text-xs text-left bg-white">
            <tbody>
              <tr v-if="anjabEntry.length > 0" v-for="(ajb, idx) in anjabEntry" class="border-b hover:bg-gray-50">
                <td class="px-4 py-2 w-20">
                  <div class="relative">
                    <img src="@/assets/images/dashboard/most-entry.svg" alt="" />
                    <span class="absolute text-lg top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-semiboldbold text-ekinblue">{{ idx + 1 }}</span>
                  </div>
                </td>
                <td class="px-4 py-2">{{ ajb.anjab }}</td>
              </tr>
              <tr v-else>
                <td class="text-center px-4 py-2">Belum ada data!</td>
              </tr>
            </tbody>
          </table>
          <div v-else role="status" class="w-full p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
            <div class="flex items-center justify-between">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full w-12"></div>
            </div>
            <div class="flex items-center justify-between pt-4">
              <div>
                <div class="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5"></div>
                <div class="w-32 h-2 bg-gray-200 rounded-full"></div>
              </div>
              <div class="h-2.5 bg-gray-300 rounded-full w-12"></div>
            </div>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="flex justify-center">
          <span class="text-xsall text-ekingray">© Aistech Solution. 2022</span>
        </div>
      </div>
    </div>
    <div class="col-span-12 md:col-span-4 bg-white px-8 pt-5 border-l order-1 md:order-2">
      <div class="flex flex-col space-y-3">
        <span class="text-ekinblue text-sm font-medium">Selamat Datang!</span>
        <div class="mx-auto relative w-12 h-12 bg-ekinblue rounded-full">
          <svg class="absolute w-6 h-6 left-0 top-0 translate-y-1/2 translate-x-1/2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
          </svg>
        </div>
        <p class="text-sm text-center font-medium">{{ nama }}</p>
        <div class="mx-auto space-x-2">
          <router-link :to="{ name: 'password' }">
            <button type="button" class="text-white bg-ekinblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-2 focus:outline-none">Ganti Password</button>
          </router-link>
        </div>
        <!-- <div class="px-10 py-5">
          <hr />
        </div> -->
        <!-- <div class="flex justify-between items-center">
          <span class="text-sm font-medium">Rekap Absensi</span>
          <Calendar panelClass="text-xs" :manualInput="false" v-model="bulanAbsen" view="month" dateFormat="MM yy" inputClass="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-3 py-2" />
        </div>
        <div class="bg-white min-h-40">
          <VueApexCharts v-if="mountChart" :key="render" width="100%" type="bar" :options="absensiOptions" :series="absensi"></VueApexCharts>
        </div> -->
        <div class="px-10 py-5">
          <hr />
        </div>
        <div class="flex justify-between items-center">
          <span class="text-sm font-medium">Penginputan Anjab</span>
          <Calendar panelClass="text-xs" :manualInput="false" v-model="bulanTask" view="month" dateFormat="MM" inputClass="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-3 py-2" />
        </div>
        <div class="bg-white min-h-40 pb-5">
          <VueApexCharts v-if="!loadingAnjab" :key="render" width="100%" :options="anjabOptions" :series="anjab"></VueApexCharts>
          <div v-else role="status" class="flex items-center justify-center h-56 w-full bg-gray-300 rounded-lg animate-pulse">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="text-white w-12 h-12">
              <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z" clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import { onBeforeMount, ref, watch } from "vue";
import Calendar from "primevue/calendar";
export default {
  components: {
    VueApexCharts,
    Calendar,
  },
  setup() {
    const loadingLaporan = ref(true);
    const loadingDataLaporan = ref(true);
    const loadingGrafikTpp = ref(true);
    const loadingTopAnjab = ref(true);
    const loadingAnjab = ref(true);
    const nama = ref(window.localStorage.getItem("nama"));
    const role = ref(window.localStorage.getItem("role"));
    const bulanAbsen = ref(new Date());
    const bulanPenilaian = ref(new Date());
    const bulanAnjab = ref(new Date());
    const bulanTask = ref(new Date());
    const tahunTPP = ref(new Date());
    const id_opd = ref("");
    const mountChart = ref(false);
    const render = ref(0);
    const tppOptions = ref({
      chart: {
        id: "tpp",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: ["JAN", "FEB", "MAR", "APR", "MEI", "JUN", "JUL", "AGT", "SEP", "OKT", "NOV", "DES"],
      },
      yaxis: {
        labels: {
          show: true,
          formatter: (value) => {
            return `${(value / 1000000).toFixed(1)} Juta`;
          },
        },
      },
      colors: ["#3c5185"],
      dataLabels: {
        enabled: false,
      },
      plotOptions: { bar: { borderRadius: 10, columnWidth: "45%" } },
    });
    const absensiOptions = ref({
      chart: {
        id: "absensi",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          show: true,
        },
        categories: ["On-Time", "Terlambat", "Izin", "Sakit"],
      },
      colors: ["#04C41A", "#FFC700", "#344CB7", "#F13838"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        showForSingleSeries: true,
      },
      yaxis: {
        show: false,
      },
      plotOptions: { bar: { borderRadius: 2, horizontal: true } },
    });
    const anjabOptions = ref({
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Belum Selesai", "Selesai"],
      colors: ["#3c5185", "#398AB9"],
      legend: {
        position: "bottom",
      },
      dataLabels: {
        enabled: false,
      },
    });
    const anjab = ref([60, 40]);
    const tpp = ref([
      {
        name: "Series 1",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ]);
    const absensi = ref([
      {
        name: "On-Time",
        data: [0],
      },
      {
        name: "Terlambat",
        data: [0],
      },
      {
        name: "Cepat Pulang",
        data: [0],
      },
      {
        name: "Izin/Sakit",
        data: [0],
      },
    ]);
    const today = ref(new Date());
    const anjabEntry = ref([]);
    const daftarOpd = ref([]);
    const laporanMasuk = ref({});
    const laporanTerima = ref({});
    const laporanTolak = ref({});

    onBeforeMount(async () => {
      await getOpd();
      await changeOpd();
    });

    watch(tahunTPP, (tpp) => getTahunTpp());
    watch(bulanAnjab, (anjab) => getMostAnjab());
    watch(bulanTask, (anjab) => getInputAnjab());
    watch(bulanAbsen, (absen) => getAbsensi());
    watch(bulanPenilaian, (Penilaian) => {
      dataNilai();
    });

    const dataNilai = async () => {
      loadingDataLaporan.value = true;
      await getLaporanMasuk();
      await getLaporanTerima();
      await getLaporanTolak();
      loadingDataLaporan.value = false;
    };

    watch(id_opd, (newopd) => {
      changeOpd();
    });

    const changeOpd = async () => {
      try {
        await getInputAnjab();
        await getMostAnjab();
        // await getAbsensi();
        await dataNilai();
        await getTahunTpp();
      } catch {
        console.log("err");
      }
      render.value += 1;
    };

    const getAbsensi = async () => {
      try {
        const absen =
          id_opd.value.length > 0
            ? await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/rekapAbsensi/${id_opd.value}/${bulanAbsen.value.getMonth()}/${bulanAbsen.value.getFullYear()}`, { credentials: "include" })
            : await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/administrator/rekapAbsensi`, { credentials: "include" });
        const result = await absen.json();
        result.data[0].on_time ? (absensi.value[0].data[0] = result.data[0].on_time) : (absensi.value[0].data[0] = 0);
        result.data[0].terlambat ? (absensi.value[1].data[0] = result.data[0].terlambat) : (absensi.value[1].data[0] = 0);
        result.data[0].cepat_pulang ? (absensi.value[2].data[0] = result.data[0].cepat_pulang) : (absensi.value[2].data[0] = 0);
        result.data[0].cuti_izin_sakit ? (absensi.value[3].data[0] = result.data[0].cuti_izin_sakit) : (absensi.value[3].data[0] = 0);
        return 0;
      } catch {
        console.log("gagal get absensi (API Dashboard)");
      }
    };

    const getInputAnjab = async () => {
      try {
        loadingAnjab.value = true;
        const anjabInput =
          id_opd.value.length > 0
            ? await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/inputAnjab/${id_opd.value}/${bulanTask.value.getMonth()}/${bulanTask.value.getFullYear()}`, { credentials: "include" })
            : await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/inputAnjab`, { credentials: "include" });
        const result = await anjabInput.json();
        result.data[0].selesai ? (anjab.value[0] = +result.data[0].selesai) : (anjab.value[0] = 0);
        result.data[0].belum_selesai ? (anjab.value[1] = +result.data[0].belum_selesai) : (anjab.value[1] = 0);
        render.value += 1;
        loadingAnjab.value = false;
        return 0;
      } catch {
        console.log("gagal get input anjab (API Dashboard)");
        loadingAnjab.value = false;
      }
    };
    const getMostAnjab = async () => {
      try {
        loadingTopAnjab.value = true;
        const mostAnjab = await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/mostEntryAnjab/${bulanAnjab.value.getMonth() + 1}`, { credentials: "include" });
        const result = await mostAnjab.json();
        anjabEntry.value = result.data;
        loadingTopAnjab.value = false;
        return 0;
      } catch {
        console.log("gagal get most anjab (API Dashboard)");
        loadingTopAnjab.value = false;
      }
    };
    const getLaporanMasuk = async () => {
      try {
        const laporan =
          id_opd.value.length > 0
            ? await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/laporanMasuk/${id_opd.value}/${bulanPenilaian.value.toISOString()}`, { credentials: "include" })
            : await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/administrator/laporanMasuk`, { credentials: "include" });
        const result = await laporan.json();
        laporanMasuk.value = result;
        return 0;
      } catch {
        console.log("gagal get laporan masuk (API Dashboard)");
      }
    };

    const getTahunTpp = async () => {
      try {
        loadingGrafikTpp.value = true;
        const gettpp =
          id_opd.value.length > 0
            ? await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/laporanTppByIdOpd/${id_opd.value}/${tahunTPP.value.getFullYear()}`, { credentials: "include" })
            : await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/laporanTpp/${tahunTPP.value.getFullYear()}`, { credentials: "include" });
        const result = await gettpp.json();
        result.data.forEach((element, x) => {
          element ? (tpp.value[0].data[x] = element) : (tpp.value[0].data[x] = 0);
        });
        render.value += 1;
        loadingGrafikTpp.value = false;
        return 0;
      } catch {
        console.log("gagal get laporan masuk (API Dashboard)");
        loadingGrafikTpp.value = false;
      }
    };

    const getLaporanTerima = async () => {
      try {
        const laporan =
          id_opd.value.length > 0
            ? await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/laporanTerima/${id_opd.value}/${bulanPenilaian.value.toISOString()}`, { credentials: "include" })
            : await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/administrator/laporanTerima`, { credentials: "include" });
        const result = await laporan.json();
        laporanTerima.value = result;
        return 0;
      } catch {
        console.log("gagal get laporan terima (API Dashboard)");
      }
    };
    const getLaporanTolak = async () => {
      try {
        const laporan =
          id_opd.value.length > 0
            ? await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/laporanTolak/${id_opd.value}/${bulanPenilaian.value.toISOString()}`, { credentials: "include" })
            : await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/dashboard/administrator/laporanTolak`, { credentials: "include" });
        const result = await laporan.json();
        laporanTolak.value = result;
        return 0;
      } catch {
        console.log("gagal get laporan tolak (API Dashboard)");
      }
    };

    const getOpd = async () => {
      try {
        const opd = await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/opd`, { credentials: "include" });
        const result = await opd.json();
        daftarOpd.value = result.data;
        return 0;
      } catch {
        console.log("gagal get opd (API Dashboard)");
      }
    };
    return {
      loadingLaporan,
      loadingDataLaporan,
      loadingTopAnjab,
      loadingAnjab,
      loadingGrafikTpp,
      laporanTolak,
      laporanTerima,
      laporanMasuk,
      tahunTPP,
      bulanAnjab,
      bulanTask,
      nama,
      tppOptions,
      render,
      mountChart,
      absensi,
      absensiOptions,
      tpp,
      anjab,
      anjabOptions,
      anjabEntry,
      today,
      role,
      bulanAbsen,
      bulanPenilaian,
      daftarOpd,
      id_opd,
    };
  },
};
</script>

<style></style>

