<template>
  <div v-click-outside="closeNotif" id="dropdownNotification" class="z-20 w-full md:w-96 bg-white rounded divide-y divide-gray-100 drop-shadow-lg absolute right-0 overflow-y-auto max-h-[500px]" aria-labelledby="dropdownNotificationButton">
    <div v-if="notifs.length > 0 || notifs2.length > 0">
      <div v-if="notifs.length > 0" class="block py-2 px-4 font-medium text-xs text-center text-gray-700 bg-gray-50">Status Laporan</div>
      <div class="divide-y divide-gray-100">
        <router-link @click="$emit('closeNotif')" :to="{ name: 'laporan-kinerja-detail', params: { id: notif.id_laporan_kinerja, tanggal: notif.tgl_lapor } }" v-for="(notif, i) in notifs" :key="i" href="#" class="flex py-3 px-4 hover:bg-gray-100">
          <div class="pl-3 w-full">
            <div class="text-ekinblue text-xs mb-1.5">{{ notif.status }}</div>
            <div class="text-xs text-blue-600">{{ new Intl.DateTimeFormat("id", { weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" }).format(new Date(notif.tgl_notifikasi)) }}</div>
          </div>
        </router-link>
      </div>
      <div v-if="notifs2.length > 0" class="block py-2 px-4 font-medium text-xs text-center text-gray-700 bg-gray-50">Laporan Masuk</div>
      <div class="divide-y divide-gray-100">
        <router-link @click="$emit('closeNotif')" :to="{ name: 'penilaian-kinerja-pegawai', params: { id: notif.id_laporan_kinerja, tanggal: notif.tgl_lapor } }" v-for="(notif, i) in notifs2" :key="i" href="#" class="flex py-3 px-4 hover:bg-gray-100">
          <div class="pl-3 w-full">
            <div class="text-ekinblue text-xs mb-1.5">{{ notif.status }}</div>
            <div class="text-xs text-blue-600">{{ new Intl.DateTimeFormat("id", { weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric" }).format(new Date(notif.tgl_notifikasi)) }}</div>
          </div>
        </router-link>
      </div>
    </div>
    <div v-else>
      <div class="block py-2 px-4 font-medium text-xs text-center text-gray-700 bg-gray-50">Notifikasi</div>
      <div class="flex py-3 px-4 justify-center hover:bg-gray-100">
        <div class="divide-y divide-gray-100">
          <div class="pl-3 w-full">
            <div class="text-xs text-blue-600 text-center">Tidak ada notifikasi terbaru!</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, ref } from "vue";
import { ClickOutside } from "vue-click-outside-of";
export default {
  props: ["notifikasi", "notifikasi2"],
  directives: { ClickOutside },
  setup(props, { emit }) {
    const read = ref(false);
    const notifs = ref([]);
    const notifs2 = ref([]);

    const closeNotif = () => {
      emit("closeNotif");
    };

    onBeforeMount(() => {
      notifs.value = props.notifikasi;
      notifs2.value = props.notifikasi2;
    });

    return { notifs, closeNotif, notifs2 };
  },
};
</script>

<style></style>
