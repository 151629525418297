<template>
  <nav class="bg-ekinblue text-gray-100 py-5 px-6 md:py-3.5 md:px-10 shadow flex justify-between items-center">
    <div class="flex items-center cursor-pointer">
      <router-link :to="{ name: 'landing-page' }">
        <img class="h-14 ml-6" v-if="informasi.length > 0" :src="`${$baseUrl}/InformasiDasar/${informasi[0].logo}`" alt="" />
      </router-link>
    </div>
    <span class="absolute md:hidden right-6 cursor-pointer text-white block py-auto" @click="MenuOpen()">
      <svg class="w-10 h-10 my-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
      </svg>
    </span>
    <ul class="md:flex md:items-center px-3 md:px-0 pb-5 md:pb-0 bg-white text-ekinblue md:text-white z-10 md:z-0 absolute md:static md:bg-transparent md:w-auto w-full top-24 duration-700 ease-in" :class="[open ? 'left-0' : 'left-[-100%]']">
      <li class="flex flex-col sm:flex sm:flex-col md:flex-row mx-6 md:mx-4 blue-ekin md:text-white" v-for="link in menu" :key="link.route">
        <a :href="'#' + link.href" class="py-4 sm:py-4 text-base font-medium pr-3">{{ link.route }}</a>
      </li>
      <router-link :to="{ name: 'login' }">
        <a v-if="!cookie" class="blue-ekin font-bold bg-white md:bg-ekinblue py-3 px-2 mx-3 md:mx-4 my-0 rounded flex">
          <svg class="w-6 h-6 blue-ekin mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"></path>
          </svg>
          Login
        </a>
        <a v-else class="blue-ekin font-bold bg-white md:bg-ekinblue py-3 px-2 mx-3 md:mx-4 my-0 rounded flex">
          {{nama}}
        </a>
      </router-link>
    </ul>
  </nav>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import { useCookies } from "vue3-cookies";

export default {
  components: {},
  props: ["menu", "informasi"],
  setup(props) {
    const informasi = ref([]);
    const open = ref(false);
    const menu = ref([]);
    const { cookies } = useCookies()
    const cookie = cookies.get("cookies");
    const nama = ref(window.localStorage.getItem('nama'))
    onBeforeMount(() => {
      informasi.value[0] = props.informasi;
      menu.value = props.menu;
    });

    function MenuOpen() {
      open.value = !open.value;
    }

    return {cookie, open, MenuOpen, menu, informasi, nama };
  },
};
</script>

<style></style>
