import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "../views/landingpage/LandingPage.vue";
import Login from "../components/landingpage/auth/Login.vue";
import Dashboard from "../views/dashboard/Dashboard.vue";
import DetailBerita from "../views/landingpage/DetailBerita.vue";
import { useCookies } from "vue3-cookies";
import { aksesHandler } from "./Akses";

const { cookies } = useCookies();

const routes = [
  {
    path: "/",
    name: "landing-page",
    component: LandingPage,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/lupa-password",
    name: "lupa-password",
    component: () => import("@/components/landingpage/auth/LupaPassword.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/berita/:id",
    name: "detail_berita",
    component: DetailBerita,
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/others/404.vue"),
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/informasi",
    name: "master-informasi",
    meta: {
      requiresAuth: true,
      menu: "master-informasi",
    },
    component: () => import("@/views/dashboard/landingpage/Informasi.vue"),
  },
  {
    path: "/admin/galeri",
    name: "master-galeri",
    meta: {
      requiresAuth: true,
      menu: "master-galeri",
    },
    component: () => import("@/views/dashboard/landingpage/Galeri.vue"),
  },
  {
    path: "/admin/produk-hukum",
    name: "master-produk-hukum",
    meta: {
      requiresAuth: true,
      menu: "master-produk-hukum",
    },
    component: () => import("@/views/dashboard/landingpage/ProdukHukum.vue"),
  },
  {
    path: "/admin/berita",
    name: "master-berita",
    meta: {
      requiresAuth: true,
      menu: "master-berita",
    },
    component: () => import("@/views/dashboard/landingpage/Berita.vue"),
  },
  {
    path: "/admin/berita/tambah",
    name: "master-berita-tambah",
    meta: {
      requiresAuth: true,
      menu: "master-berita",
    },
    component: () => import("@/views/dashboard/landingpage/BeritaForm.vue"),
  },
  {
    path: "/admin/berita/:id",
    name: "master-berita-edit",
    meta: {
      requiresAuth: true,
      menu: "master-berita",
    },
    component: () => import("@/views/dashboard/landingpage/BeritaForm.vue"),
  },
  {
    path: "/biodata",
    name: "biodata",
    meta: {
      requiresAuth: true,
      menu: null,
    },
    component: () => import("@/views/dashboard/user/Biodata.vue"),
  },
  {
    path: "/password",
    name: "password",
    meta: {
      requiresAuth: true,
      menu: null,
    },
    component: () => import("@/views/dashboard/user/Password.vue"),
  },
  {
    path: "/struktur/:id",
    name: "struktur",
    meta: {
      layout: "blank",
      requiresAuth: true,
      menu: null,
    },
    component: () => import("@/views/dashboard/struktur/Struktur.vue"),
  },
  {
    path: "/menu",
    name: "menu",
    meta: {
      requiresAuth: true,
      menu: "menu",
    },
    component: () => import("@/views/dashboard/masterdata/Menu.vue"),
  },
  {
    path: "/akses",
    name: "hak-akses",
    meta: {
      requiresAuth: true,
      menu: "hak-akses",
    },
    component: () => import("@/views/dashboard/masterdata/HakAkses.vue"),
  },
  {
    path: "/pegawai",
    name: "pegawai",
    meta: {
      requiresAuth: true,
      menu: "pegawai",
    },
    component: () => import("@/views/dashboard/masterdata/Pegawai.vue"),
  },
  {
    path: "/manage-laporan",
    name: "manage-laporan",
    meta: {
      requiresAuth: true,
      menu: "manage-laporan",
    },
    component: () => import("@/views/dashboard/masterdata/ManageLaporan.vue"),
  },
  {
    path: "/hari-libur",
    name: "hari-libur",
    meta: {
      requiresAuth: true,
      menu: "hari-libur",
    },
    component: () => import("@/views/dashboard/masterdata/HariLibur.vue"),
  },
  {
    path: "/shift",
    name: "shift",
    meta: {
      requiresAuth: true,
      menu: "shift",
    },
    component: () => import("@/views/dashboard/masterdata/Shift.vue"),
  },
  {
    path: "/laporan-opd",
    name: "laporan-opd",
    meta: {
      requiresAuth: true,
      menu: "laporan-opd",
    },
    component: () => import("@/views/dashboard/laporan/LaporanOpd.vue"),
  },
  {
    path: "/user",
    name: "user",
    meta: {
      requiresAuth: true,
      menu: "user",
    },
    component: () => import("@/views/dashboard/masterdata/UserManagement.vue"),
  },
  {
    path: "/verifikator",
    name: "verifikator",
    meta: {
      requiresAuth: true,
      menu: "verifikator",
    },
    component: () => import("@/views/dashboard/masterdata/Verifikator.vue"),
  },
  {
    path: "/opd",
    name: "opd",
    meta: {
      requiresAuth: true,
      menu: "opd",
    },
    component: () => import("@/views/dashboard/masterdata/Opd.vue"),
  },
  {
    path: "/opd/eseloniii/:id/:opd",
    name: "opd-eselon-3",
    meta: {
      requiresAuth: true,
      menu: "opd",
    },
    component: () => import("@/views/dashboard/masterdata/Eselon3.vue"),
  },
  {
    path: "/opd/eseloniv/:id/:bidang",
    name: "opd-eselon-4",
    meta: {
      requiresAuth: true,
      menu: "opd",
    },
    component: () => import("@/views/dashboard/masterdata/Eselon4.vue"),
  },
  {
    path: "/jabatan",
    name: "jabatan",
    meta: {
      requiresAuth: true,
      menu: "jabatan",
    },
    component: () => import("@/views/dashboard/masterdata/Jabatan.vue"),
  },
  {
    path: "/tpp",
    name: "tpp",
    meta: {
      requiresAuth: true,
      menu: "tpp",
    },
    component: () => import("@/views/dashboard/masterdata/TPP.vue"),
  },
  {
    path: "/analisisjabatan",
    name: "analisis-jabatan",
    meta: {
      requiresAuth: true,
      menu: "analisis-jabatan",
    },
    component: () => import("@/views/dashboard/anjab/AnalisisJabatan.vue"),
  },
  {
    path: "/laporan-pegawai",
    name: "laporan-kinerja",
    meta: {
      requiresAuth: true,
      menu: "laporan-kinerja",
    },
    component: () => import("@/views/dashboard/laporan/LaporanKinerja.vue"),
  },
  {
    path: "/laporan-pegawai/detail",
    name: "laporan-kinerja-detail",
    meta: {
      requiresAuth: true,
      menu: "laporan-kinerja",
    },
    component: () => import("@/views/dashboard/laporan/LaporanKinerjaDetail.vue"),
  },
  {
    path: "/input-absensi",
    name: "input-absensi",
    meta: {
      requiresAuth: true,
      menu: "input-absensi",
    },
    component: () => import("@/views/dashboard/absensi/InputAbsensi.vue"),
  },
  {
    path: "/input-absensi/tambah",
    name: "input-absensi-tambah",
    meta: {
      requiresAuth: true,
      menu: "input-absensi",
    },
    component: () => import("@/views/dashboard/absensi/AbsensiForm.vue"),
  },
  {
    path: "/laporan-pegawai/rekapan/:id/:tanggal",
    name: "laporan-kinerja-rekapan",
    meta: {
      requiresAuth: true,
      menu: "laporan-kinerja",
    },
    component: () => import("@/views/dashboard/laporan/LaporanKinerjaRekapan.vue"),
  },
  {
    path: "/laporan-penilaian",
    name: "penilaian-pegawai",
    meta: {
      requiresAuth: true,
      requiresAdminAuth: true,
      requiresPenilaiAuth: true,
      menu: "penilaian-pegawai",
    },
    component: () => import("@/views/dashboard/penilaian/LaporanPegawai.vue"),
  },
  {
    path: "/laporan-penilaian/kinerja/:id/:tanggal",
    name: "penilaian-kinerja-pegawai",
    meta: {
      requiresAuth: true,
      menu: "penilaian-pegawai",
    },
    component: () => import("@/views/dashboard/penilaian/LaporanPenilaianPegawai.vue"),
  },
  {
    path: "/laporan-penilaian/laporan",
    name: "daftar-laporan-pegawai",
    meta: {
      requiresAuth: true,
      menu: "penilaian-pegawai",
    },
    component: () => import("@/views/dashboard/penilaian/LaporanPerHari.vue"),
  },
  {
    path: "/laporan-penilaian/rekapan/:id",
    name: "penilaian-kinerja-rekapan",
    meta: {
      requiresAuth: true,
      menu: "penilaian-pegawai",
    },
    component: () => import("@/views/dashboard/penilaian/RekapanLaporanPegawai.vue"),
  },
  {
    path: "/laporan-penilaian/rekapan/:id/:tanggal",
    name: "penilaian-kinerja-rekapan-detail",
    meta: {
      requiresAuth: true,
      menu: "penilaian-pegawai",
    },
    component: () => import("@/views/dashboard/penilaian/DetailRekapanLaporanPegawai.vue"),
  },
  {
    path: "/laporan-penilaian/nilai/:id",
    name: "nilai-pegawai",
    meta: {
      requiresAuth: true,
      menu: "penilaian-pegawai",
    },
    component: () => import("@/views/dashboard/penilaian/FormNilai.vue"),
  },
  {
    path: "/absensi",
    name: "absensi",
    meta: {
      requiresAuth: true,
      menu: "absensi",
    },
    component: () => import("@/views/dashboard/absensi/Absensi.vue"),
  },
  {
    path: "/rekapan-absensi-opd",
    name: "rekapan-absensi-opd",
    meta: {
      requiresAuth: true,
      menu: "rekapan-absensi-opd",
    },
    component: () => import("@/views/dashboard/absensi/RekapanAbsensi.vue"),
  },
  {
    path: "/rekapan-absensi-opd/perhari",
    name: "rekapan-absensi-opd-perhari",
    meta: {
      requiresAuth: true,
      menu: "rekapan-absensi-opd-perhari",
    },
    component: () => import("@/views/dashboard/absensi/RekapanAbsensiPerHari.vue"),
  },
  {
    path: "/akses-laporan",
    name: "akses-laporan",
    meta: {
      requiresAuth: true,
      menu: "akses-laporan",
    },
    component: () => import("@/views/dashboard/tpp/AksesPelaporan.vue"),
  },
  {
    path: "/pengajuan-tpp",
    name: "rekapitulasi-pengajuan-tpp",
    meta: {
      requiresAuth: true,
      menu: "rekapitulasi-pengajuan-tpp",
    },
    component: () => import("@/views/dashboard/tpp/StatusTpp.vue"),
  },
  {
    path: "/pengajuan-tpp-detail",
    name: "rekapitulasi-pengajuan-tpp-detail",
    meta: {
      requiresAuth: true,
      menu: "rekapitulasi-pengajuan-tpp",
    },
    component: () => import("@/views/dashboard/tpp/RekapitulasiPengajuanTpp.vue"),
  },
  {
    path: "/status-tpp/revisi/",
    name: "revisi-tpp",
    meta: {
      requiresAuth: true,
      menu: "rekapitulasi-pengajuan-tpp",
    },
    component: () => import("@/views/dashboard/tpp/RevisiPenolakanTpp.vue"),
  },
  {
    path: "/tpp-submit-verifikator",
    name: "submittpp-verifikator",
    meta: {
      requiresAuth: true,
      menu: "rekapitulasi-pengajuan-tpp",
    },
    component: () => import("@/views/dashboard/tpp/SubmitVerifikator.vue"),
  },
  {
    path: "/penerbitan-tpp",
    name: "penerbitan-tpp",
    meta: {
      requiresAuth: true,
      menu: "penerbitan-tpp",
    },
    component: () => import("@/views/dashboard/verifikator/PenerbitanTpp.vue"),
  },
  {
    path: "/penerbitan-tpp/dokumen/:id/:opd",
    name: "penerbitan-tpp-dokumen",
    meta: {
      requiresAuth: true,
      menu: "penerbitan-tpp",
    },
    component: () => import("@/views/dashboard/verifikator/PenerbitanTppDokumen.vue"),
  },
  {
    path: "/penerbitan-tpp/detail/:id_tpp/:id_opd/:opd/:bulan",
    name: "penerbitan-tpp-detail",
    meta: {
      requiresAuth: true,
      menu: "penerbitan-tpp",
    },
    component: () => import("@/views/dashboard/verifikator/PenerbitanTppDetail.vue"),
  },
  {
    path: "/penerbitan-tpp/terbit/:id",
    name: "penerbitan-tpp-terbit",
    meta: {
      requiresAuth: true,
      menu: "penerbitan-tpp",
    },
    component: () => import("@/views/dashboard/verifikator/SuratPenerbitanTpp.vue"),
  },
  {
    path: "/penerbitan-tpp/terbit/detail/:id",
    name: "penerbitan-tpp-terbit-detail",
    meta: {
      requiresAuth: true,
      menu: "penerbitan-tpp",
    },
    component: () => import("@/views/dashboard/verifikator/SuratPenerbitanTppDetail.vue"),
  },
  {
    path: "/dokumen-persyaratan",
    name: "dokumen-persyaratan",
    meta: {
      requiresAuth: true,
      menu: "dokumen-persyaratan",
    },
    component: () => import("@/views/dashboard/verifikator/PersyaratanDokumen.vue"),
  },
  {
    path: "/penanda-tangan",
    name: "penanda-tangan",
    meta: {
      requiresAuth: true,
      menu: "penanda-tangan",
    },
    component: () => import("@/views/dashboard/masterdata/Ttd.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

router.beforeEach(async (to, from) => {
  let access = true;
  if (!cookies.get("cookies")) window.localStorage.clear();
  if (to.meta.requiresAuth && !cookies.get("cookies")) access = false;
  if (access == false) return { name: "login", query: { redirect: to.fullPath } };
  if (to.meta.requiresAuth && to.meta.menu && window.localStorage.getItem("role") !== "administrator") {
    const akses = await aksesHandler(to);
    if (!akses.length > 0) {
      return { name: "404" };
    } else {
      to.params = { ...to.params, edit: akses[0].edit, delete: akses[0].delete, create: akses[0].delete };
    }
  }
  if (to.name == "login" && cookies.get("cookies")) return { name: "dashboard" };
  if (to.query?.redirect && from.name == "login") return { path: to.query.redirect };
});

export default router;
