<template>
  <section id="fifthpage" class="bg-[url('~assets/images/landingpage/footer.svg')] bg-top bg-no-repeat bg-cover">
    <div v-animate-onscroll="'animate__animated animate__fadeInUp'">
      <div class="container mx-auto px-10 md:px-20 pt-80 md:pt-65">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 pb-10 border-b-2 md:space-y-0 space-y-4">
          <div class="flex flex-col bg-transparent space-y-6">
            <div class="">
              <img class="w-full" v-if="informasi.length > 0" :src="`${$baseUrl}/InformasiDasar/${informasi[0].logo}`" alt="" />
            </div>
            <span class="text-white text-lg" v-if="informasi.length > 0"> {{ informasi[0].info_lain }} </span>
          </div>
          <div class="flex flex-col bg-transparent space-y-2 text-white font-medium md:mx-auto">
            <span class="text-xl">E-Kinerja</span>
            <div class="" v-for="link in menu" :key="link.route">
              <a :href="'#' + link.href" class="py-4 sm:py-4 text-base font-normal pr-3">{{ link.route }}</a>
            </div>
          </div>
          <div class="flex flex-col bg-transparent space-y-2 text-white font-medium md:ml-auto">
            <span class="text-xl">Hubungi Kami</span>
            <span class="text-base font-normal pr-3">{{ informasi.length > 0 ? informasi[0].email : "" }}</span>
            <span class="text-xl">{{ informasi[0].no_tlp }}</span>
            <span class="text-base font-normal pr-3">{{ informasi.length > 0 ? informasi[0].alamat : "" }}</span>
            <div class="flex space-x-4">
              <a :href="`https://www.facebook.com/${informasi[0].facebook}`">
                <img class="rounded-t-lg" src="@/assets/images/landingpage/fb.svg" alt="" />
              </a>
              <a :href="`https://www.instagram.com/${informasi[0].instagram}`">
                <img class="rounded-t-lg" src="@/assets/images/landingpage/ig.svg" alt="" />
              </a>
              <a :href="`https://www.twitter.com/${informasi[0].twitter}`">
                <img class="rounded-t-lg" src="@/assets/images/landingpage/twitter.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div class="py-4 text-center">
          <span class="text-white">Copyright Aistech © 2022. All Rights Reserved.</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onBeforeMount } from "vue";
export default {
  props: ["menu", "informasi"],
  setup(props) {
    const menu = ref([]);
    const informasi = ref([]);
    onBeforeMount(() => {
      informasi.value[0] = props.informasi;
      menu.value = props.menu;
    });

    return { menu, informasi };
  },
};
</script>

<style scoped></style>
