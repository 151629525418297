<template>
  <nav class="bg-white border-gray-200 px-4 rounded border-b">
    <div class="container mx-auto flex flex-wrap justify-between items-center w-full">
      <div v-if="!dashboard">
        <a role="button" @click="$router.go(-1)" class="text-ekinblue">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path></svg>
        </a>
      </div>
      <div class="ml-auto flex md:w-4/12 py-4 md:py-5">
        <ul class="flex justify-between space-x-3 text-sm md:mt-0 md:text-xl md:font-medium md:pr-14 ml-auto">
          <div class="hidden md:flex text-sm text-ekinblue"> {{user}}</div>
          <div class="flex">
            <span class="md:hidden text-ekinblue" @click="$emit('toggleSide')">
              <svg class="w-6 h-6 my-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </span>
          </div>
          <li v-if="role !== 'administrator'">
            <button
              @click="
                () => {
                  if (!closed) {
                    toggleNotification();
                  } else {
                    closed = false;
                  }
                }
              "
              class="relative inline-flex items-center text-sm font-medium text-center text-ekinblue hover:text-blue-900 focus:outline-none"
              type="button"
            >
              <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path></svg>
              <div v-if="read" class="absolute top-0 right-0">
                <div v-if="statusNotifikasi > 0 || statusNotifikasi2 > 0 " class="animate-bounce w-3 h-3 bg-red-500 rounded-full border-2 border-white dark:border-gray-900"></div>
              </div>
            </button>
            <Notification
              :key="render"
              :notifikasi="notification"
              :notifikasi2="notification2"
              v-if="showNotification"
              @closeNotif="
                () => {
                  if (showNotification) closed = true;
                  toggleNotification();
                }
              "
            />
          </li>
          <li>
            <a href="#" @click="logout" class="text-ekinblue">
              <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd"></path></svg
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, computed, watch, onBeforeMount } from "vue";
import { useCookies } from "vue3-cookies";
import Notification from "../admin/alerts/Notification.vue";
export default {
  components: {
    Notification,
  },
  setup() {
    const render = ref(0);
    const read = ref(true);
    const router = useRouter();
    const { cookies } = useCookies();
    const showNotification = ref(false);
    const role = ref(window.localStorage.getItem("role"));
    const notification = ref([]);
    const notification2 = ref([]);
    const closed = ref(false);
    const user = ref(window.localStorage.getItem("username"));
    const dashboard = computed(() => {
      if (router.currentRoute.value.name == "dashboard") {
        return true;
      }
    });

    const statusNotifikasi = computed(() => {
      return notification.value.filter((item) => item.status_lihat == false).length;
    });
    const statusNotifikasi2 = computed(() => {
      return notification2.value.filter((item) => item.status_lihat == false).length;
    });

    const logout = () => {
      cookies.remove("cookies");
      router.push({ name: "login", query: { stat: true } });
    };

    const toggleNotification = () => {
      if (!showNotification.value) render.value += 1;
      showNotification.value = !showNotification.value;
    };

    onBeforeMount(() => {
      if (role.value != "administrator") getNotifications();
    });

    watch(showNotification, (x) => {
      if (!showNotification.value) getNotifications();
    });

    const getNotifications = async () => {
      try {
        const request = await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/notifikasi/laporanKinerja/${window.localStorage.getItem("id_pegawai")}`, { credentials: "include" });
        const response = await request.json();
        const request2 = await fetch(`${process.env.VUE_APP_ENDPOINT}/api/v1/notifikasi/laporanKinerjaPegawai/${window.localStorage.getItem("id_jabatan")}`, { credentials: "include" });
        const response2 = await request2.json();
        notification.value = response.data;
        notification2.value = response2.data;
      } catch {
        console.log("Notifikasi Gagal!");
      }
    };

    return { user,closed, statusNotifikasi, statusNotifikasi2, dashboard, read, logout, showNotification, toggleNotification, render, role, notification, notification2 };
  },
};
</script>

<style></style>
