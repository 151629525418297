<template>
  <section id="fourthpage" class="bg-white duration-700 ease-in-out">
    <div class="container block mx-auto md:px-14 space-y-10">
      <div class="text-center pt-32 md:pt-44">
        <div v-animate-onscroll="'animate__animated animate__fadeInUp'">
          <span class="text-xl md:text-5xl font-semibold text-ekinblue leading-tight md:pb-5"> Berita </span>
        </div>
      </div>
      <div v-if="showBerita" v-animate-onscroll="'animate__animated animate__fadeInRight'">
        <Carousel v-if="berita.length > 1" :value="berita" :numScroll="3" :numVisible="3" :responsiveOptions="responsiveOptions" >
          <template #item="slotProps">
            <div class=" flex flex-col mb-4 bg-white hover:bg-light rounded-md shadow-xl h-100 md:mx-4">
              <div class="h-52">
                <img class="rounded-t-lg h-full w-full object-contain " :src="`${$baseUrl}/berita/${slotProps.data.foto}`" alt="" />
              </div>
              <div class="p-6 space-y-4 flex flex-col">
                <p class="font-medium text-xl text-black">
                  {{ slotProps.data.judul }}
                </p>
                <div class="flex justify-between text-sm">
                  <span class="bg-blue-100 text-ekinblue font-semibold mr-2 px-3 py-0.5 rounded-full">{{slotProps.data.kategori}}</span>
                  <span class="text-ekingray">{{ new Intl.DateTimeFormat("id", { dateStyle: "full" }).format(new Date(slotProps.data.tgl_berita)) }}</span>
                </div>
                <div class="w-full text-gray-400 font-normal text-xl md:text-sm text-justify w-100">
                  <!-- <div class="overflow-hidden truncate " v-html="slotProps.data.isi"></div> -->
                </div>
                <div class="ml-auto">
                  <router-link :to="{ name: 'detail_berita', params: { id: slotProps.data.id } }">
                    <button type="button" class="text-white bg-ekinblue hover:ekinblue focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xl md:text-sm px-5 py-2 focus:outline-none">Selanjutnya</button>
                  </router-link>
                </div>
              </div>
            </div>
          </template>
        </Carousel>
        <Carousel v-else-if="berita.length < 2 && berita.length !=0" :value="berita" :numScroll="3" :numVisible="3" :responsiveOptions="responsiveOptions" >
          <template #item="slotProps">
            <div class=" flex flex-col mb-4 md:w-80 bg-white hover:bg-light rounded-md drop-shadow-xl border border-t-0 z-50 h-100 md:mx-4">
              <div class="h-52">
                <img class="rounded-t-lg h-full w-full object-contain " :src="`${$baseUrl}/berita/${slotProps.data.foto}`" alt="" />
              </div>
              <div class="p-6 space-y-4 flex flex-col">
                <p class="font-medium text-xl text-black">
                  {{ slotProps.data.judul }}
                </p>
                <div class="flex justify-between text-sm">
                  <span class="bg-blue-100 text-ekinblue font-semibold mr-2 px-3 py-0.5 rounded-full">{{slotProps.data.kategori}}</span>
                  <span class="text-ekingray">{{ new Intl.DateTimeFormat("id", { dateStyle: "full" }).format(new Date(slotProps.data.tgl_berita)) }}</span>
                </div>
                <div class="w-full text-gray-400 font-normal text-xl md:text-sm text-justify w-100">
                  <!-- <div class="overflow-hidden truncate " v-html="slotProps.data.isi"></div> -->
                </div>
                <div class="ml-auto">
                  <router-link :to="{ name: 'detail_berita', params: { id: slotProps.data.id } }">
                    <button type="button" class="text-white bg-ekinblue hover:ekinblue focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xl md:text-sm px-5 py-2 focus:outline-none">Selanjutnya</button>
                  </router-link>
                </div>
              </div>
            </div>
          </template>
        </Carousel>
        <div v-else class="flex flex-col py-12 md:py-24 px-5 items-center">
          <img src="@/assets/images/illustrations/oc-browse.svg" alt="" class="md:w-1/12 w-3/12" />
          <div class="flex flex-col md:w-5/12 w-9/12 text-ekinblue items-center">
            <span class="text-lg font-bold">Data kosong!</span>
            <span class="">Berita tidak ditemukan!</span>
          </div>
        </div>
      </div>
      <div v-else class="flex flex-col py-10">
        <div class="flex my-auto">
          <button disabled type="button" class="py-2.5 px-5 mx-auto text-xs font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 inline-flex items-center">
            <svg aria-hidden="true" role="status" class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="#1C64F2"
              />
            </svg>
            Loading...
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, onMounted, onBeforeMount } from "vue";
import Carousel from "primevue/carousel";
export default {
  components: {
    Carousel,
  },
  setup() {
    const showBerita = ref(false);
    const berita = ref([]);
    const responsiveOptions = ref([
      {
        breakpoint: "2080px",
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: "1024px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "480px",
        numVisible: 1,
        numScroll: 1,
      },
    ]);

    onBeforeMount(() => {
      getBerita();
    });

    function getBerita() {
      Promise.all([fetch(`${process.env.VUE_APP_ENDPOINT}/berita/api/V1/dataBerita/public`)])
        .then(async ([data]) => {
          const result = await data.json();
          berita.value = result.data;
          showBerita.value = true;
        })
        .catch((err) => console.log(err));
    }

    return { berita, showBerita, responsiveOptions };
  },
};
</script>

<style scoped>
:deep() .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #3c5185 !important;
}
</style>
